import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { SelectConsultData, UpdateConsultaData } from "../utils/consultdata";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { openGlobalSnack } from "../utils/snack";
import { UserInfo, userGetInfo } from "../../user/info";
import { CircularLoading } from "../utils/loading";
import {GeralTable, TableWithSearchAndSortEngine} from "../utils/table";
import { formatDateWithMin } from "../../utils/date";
import { formatLocalCurrency } from "../../utils/currency";
import { getTableNameByID } from "../dashboard/types";
import { PropostalInfoComplete, PropostalStatus, propostalStatusPTBR, propostalStatusPTBRToNormal } from "../types/propostals";
import { TabDinamic } from "../utils/tabs";
import { UploadDocForm } from "../whatsapp/uploaddoc";
import { getComissionByTableName } from "../dashboard/propostals";
import { PendencyForm } from "../whatsapp/pendency";

function PropostalCompleteInfo({propostalId,close}:{propostalId?:number,close:()=>any}){
    const [loading,setLoading]=useState(false);
    const [propostalInfo,setPropostalInfo]=useState<false|PropostalInfoComplete>(false);
    const [blockClose,setBlockClose]=useState(false);
    useEffect(()=>{
      if(!propostalId){
         if(propostalInfo){
            setPropostalInfo(false);
         } 
         return;  
      }
      setLoading(true);  
    },[propostalId]);
    useEffect(()=>{
      if(!loading)
          return;
      sendServerRequest("GET",`${URLS.propostals.getInfo}/${propostalId}`).then(e=>{
         setPropostalInfo(e.data.data);
         setLoading(false);
      }).catch(e=>{
          openGlobalSnack('Erro ao carregar proposta','error');
          setLoading(false);
      }) 

    },[loading]);

    return (
        <Dialog open={Boolean(propostalId)}>
             <DialogTitle>Informações das proposta</DialogTitle>
             <DialogContent>
                 {loading?<CircularLoading/>:
                 <div>
                   {propostalInfo?<TabDinamic
                      items={[
                        {label:'Informações',
                         item:<div>
                         <p>{`Valor: ${formatLocalCurrency(propostalInfo.client_withdrawn_value)}`}</p>
                         <p>{`Status: ${propostalStatusPTBR(propostalInfo.liveStatus)}`}</p>
                         {propostalInfo&&propostalInfo.liveStatus==PropostalStatus.formalization?<div>
                         <Button variant='contained' onClick={()=>{navigator.clipboard.writeText(propostalInfo.formalizationLink).then(e=>openGlobalSnack('Link Copiado Com Sucesso','success'))}}>Copiar Link de Formalização</Button>
                         </div>:null}
                         {propostalInfo.statusReason?<p>{`Status Info: ${propostalInfo.statusReason}`}</p>:null}
                         {propostalInfo.client&&propostalInfo.client.name&&propostalInfo.client.cpf?<div>
                             <p>{`Cliente Nome: ${propostalInfo.client.name}`}</p>
                             <p>{`Cliente CPF: ${propostalInfo.client.cpf}`}</p>
                         </div>:null}
                         <p>{`Data de criação ${formatDateWithMin(new Date(propostalInfo.createdAt))}`}</p>
                         <p>{`Ultima atulização ${formatDateWithMin(new Date(propostalInfo.updatedAt))}`}</p>
                         <p>{`Comissionado: `}<span style={{color:`${(comissioned())?'green':'red'}`}}>{`${(comissioned())?'SIM':'NÃO'}`}</span></p>
                        
                         {comissioned()?<p>{`Comissão: ${formatLocalCurrency(0.5*propostalInfo.client_withdrawn_value*getComissionByTableName(getTableNameByID(propostalInfo.table_fee_id)))}`}</p>:null}
                         {comissioned()?<CallToSupportPropostalButton setPropostalComissioned={()=>{setLoading(true)}} upLoading={(v)=>setBlockClose(v)} propostalInfo={propostalInfo}/>:''}
                         
                         {propostalInfo.paidComissiom?<p style={{color:'green'}}>"Você recebeu comissão!"</p>:null}
                        </div>
                        },
                        {
                            label:'Documentos',
                            item:<UploadDocForm onUploaded={()=>{
                                setLoading(true);
                            }} cpf={propostalInfo.client?.cpf||''}/>
                        },
                        {
                            label:'Pendencias',
                            item:<PendencyForm onUpdated={()=>setLoading(true)} cpf={propostalInfo.client?.cpf||''}/>,
                            disabled:propostalInfo.liveStatus!==PropostalStatus.pending
                        }
                        

                      ]}
                   />:<></>}
                </div>}
             </DialogContent>
             <DialogActions>
                <Button disabled={blockClose} onClick={close} variant="contained">Fechar</Button>
             </DialogActions>
        </Dialog>
    )
    function comissioned(){
        if(!propostalInfo)
            return false;
        return propostalInfo.comissioned||propostalInfo.paidComissiom||propostalInfo.userComission?.comissionPorcetage;
    }
    
}
function CallToSupportPropostalButton({propostalInfo,upLoading,setPropostalComissioned}:{propostalInfo:PropostalInfoComplete,upLoading:(v:boolean)=>any,setPropostalComissioned:()=>any}){
    const [loading,setLoading]=useState(false);
    useEffect(()=>{

    },[propostalInfo]);
    useEffect(()=>{
       if(!loading){
           upLoading(false);
           return;
       }   
       upLoading(loading);
       if(propostalInfo.hasSupport){
          navigator.clipboard.writeText(`https://wa.me/${propostalInfo.clientPhone}`).then(e=>{
             setLoading(false);
             openGlobalSnack('WhatsApp Copiado com Sucesso!','success');
             
          }).catch(e=>{
            openGlobalSnack('Erro ao copiar Número','error');
            setLoading(false);
          })
       }else{
            sendServerRequest('POST',`${URLS.propostals.setSupport}/${propostalInfo.id}`).then(e=>{
                setPropostalComissioned();
                openGlobalSnack('Suporte solicitado com sucesso.','success');
                setLoading(false);
            }).catch(e=>{
                openGlobalSnack('Erro ao solicitar suporte','error');
                setLoading(false);
            })
       }
    },[loading])
    return (
        <LoadingButton onClick={()=>{setLoading(true)}} variant="contained" loading={loading} disabled={propostalInfo.hasSupport&&!propostalInfo.userComission}>{`${propostalInfo.hasSupport?`${propostalInfo.userComission?'Link WhatsApp':'Outro Usuário'}`:'Comissionar Proposta'}`}</LoadingButton>
    )
}
export function Propostals(){
    const [data,setData]=useState<null|UpdateConsultaData>();
    const [loading,setLoading]=useState(false);
    const [userInfo,setUserInfo]=useState<false|UserInfo>(false);
    const [propostals,setPropostals]=useState<false|Array<any>>(false);
    
    useEffect(()=>{
       userGetInfo().then(e=>{
           setUserInfo(e);
       }).catch(e=>{
           window.location.reload();
       })
       return ()=>{};
    },[])
    useEffect(()=>{
        if(!loading)
             return;
        if(!data)
             return;
        
        sendServerRequest("POST",URLS.propostals.getComplete,{
            fromDate:data.start,
            toDate:data.end,
            status:propostalStatusPTBRToNormal(data.propostalStatus||[]),
            v8Ids:data.v8,
        }).then(e=>{
            
            setPropostals(e.data.data.propostals);
            setLoading(false);
        }).catch(e=>{
            
            openGlobalSnack('Erro ao carregar propostas','error');
            setLoading(false);
        });        
    },[loading])
    return (
        <div style={{textAlign:'center'}}>
            <Typography variant="h5" style={{marginBottom:'30px'}}>Lista de Propostas</Typography>
       {userInfo?
       <>
       {userInfo.permission.can_see_complete_propostal_info?
            <div>
            <SelectConsultData disabled={loading} disableWA={true} onUpdate={(d)=>{setData(d)}}/>
            <LoadingButton loading={loading} disabled={!data||!data.v8||data.v8.length<1} variant="contained" onClick={()=>setLoading(true)}>Consultar Propostas</LoadingButton>
            <PropostalList propostals={propostals}/>
            </div>
       :<p>Acesso Negado.</p>}
        </>:<CircularLoading/>}
        </div>
    )
}

function PropostalList({propostals}:{propostals:false|Array<any>}){
   const [selectedPropostal,setSelectedPropostal]=useState<undefined|number>(undefined);
   useEffect(()=>{
      
   },[propostals]);
   if(!propostals)
     return null;
   return (
     <div style={{marginTop:'30px'}}>
        {propostals.length<1?<p>Sem Propostas</p>:
        <div>
           <TableWithSearchAndSortEngine items={propostals} header={[
             {label:'Nome',id:'client_name',allowSort:false,showValue:(i)=>i.client.name},
             {label:'CPF',id:'cpf',showValue:(i)=>i.client.cpf},
             {label:'Valor (R$)',id:'client_withdrawn_value',allowSort:true,showValue:(i)=>formatLocalCurrency(i.client_withdrawn_value)},
             {label:'Data de Criação',id:'createdAt',allowSort:true,showValue:(i)=>{return formatDateWithMin(i.createdAt)}},
             {label:'Tabela',id:'table_fee_id',showValue:(i)=>getTableNameByID(i.table_fee_id)},
             {label:'Comissionado',allowSort:true,id:'comissioned',showValue:(i)=><p style={{color:`${i.comissioned?'green':'red'}`}}>{`${i.comissioned?'SIM':'NÃO'}`}</p>}, 
             {label:'Status',id:'status',showValue:(i)=>propostalStatusPTBR(i.status)}, 
             {label:'Ver Mais',id:'see_moro',showValue:(i)=>{return (<Button onClick={()=>{setSelectedPropostal(i.id)}} variant="contained">Ver Mais</Button>)}}
           ]}
           filters={[{label:'Em Suporte',filter:(i:any)=>{return i.userSupport&&i.status!==PropostalStatus.paid}},{label:'Precisa Suporte',filter:(i)=>i.comissioned&&!i.hasSupport},{label:'Comissão Paga',filter:(i)=>{return i.paidComissiom}}]}
           />
        </div>}
        <PropostalCompleteInfo close={()=>setSelectedPropostal(undefined)} propostalId={selectedPropostal}/>
     </div>
   )
}