import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

const messages=[
    {key:'/rgb',message:'Por favor, envie-me uma foto nítida da parte frontal e outra da parte traseira do seu RG. Preciso atualizar sua proposta.'},
    {key:'/rgf',message:'Por favor, envie-me uma foto nítida da frente do seu RG. Preciso atualizar sua proposta.'},
    {key:'/rgt',message:'Por favor, envie-me uma foto nítida da parte de trás do seu RG. Preciso atualizar sua proposta.'},
    {key:'/cnh',message:'Por favor, envie-me uma foto nítida da sua CNH aberta. Preciso atualizar sua proposta.'},
    {key:'/analisemanual',message:'Sua proposta foi para análise manual. Em breve, o seu dinheiro será depositado na conta do Pix informada.'},
    {key:'/cnhPDF',message:'Por favor, envie-me o arquivo PDF da sua CNH. Preciso atualizar sua proposta.'},
    {key:'workb',message:'Por favor, envie-me uma foto nítida da parte frontal e outra da traseira de sua carteira de trabalho. Preciso atualizar sua proposta.'},
    {key:'workf',message:'Por favor, envie-me uma foto nítida da frente da sua carteira de trabalho. Preciso atualizar sua proposta.'},
    {key:'workt',message:'Por favor, envie-me uma foto nítida da parte de trás da sua carteira de trabalho. Preciso atualizar sua proposta.'},
    
 ] 
export function ReadyMessagesList({text,ignore,height,onselect}:{text:string,ignore:boolean,height:number,onselect:(s:{key:string,message:string})=>any}){
    const [showMessage,setShowMessages]=useState<Array<{key:string,message:string}>>([]);
    useEffect(()=>{
        let nshow=[];
        for(let m of messages){
            if(m.key.indexOf(text)>-1){
                nshow.push(m);
            }
        }
        setShowMessages(nshow);
    },[text])
    if(ignore)
       return null;
    return (

        <List 
           sx={{
            overflow: 'auto',
            maxHeight: height,
            '& ul': { padding: 0 },
            padding:0,
           }}
        >
            {showMessage.map(s=>{
               return (
                  <ListItem sx={{padding:0}}>
                      <ListItemButton sx={{padding:0}} onClick={()=>onselect(s)}>
                          <ListItemText primaryTypographyProps={{fontSize:'12px'}} secondaryTypographyProps={{fontSize:'10px'}} primary={s.message} secondary={s.key}/>
                      </ListItemButton>
                  </ListItem>
               )
            })}
        </List>

    )
     

}