import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useEffect,useState } from 'react';
import Container from '@mui/material/Container';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';

const drawerWidth: number = 210;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

interface MenuItem{
    item:JSX.Element,
    url?:string,
    content?:JSX.Element,
}
export interface ResponseMenuProps{
    menuPrimary:Array<MenuItem>,
    menuSecondary?:Array<MenuItem>
    headerText:string;
}
export default function ResponsiveMenu({menuPrimary,menuSecondary,headerText}:ResponseMenuProps) {
  const [open, setOpen] = React.useState(false);
  const [component,setComponent]=useState<null|undefined|JSX.Element>(null);
  const nav=useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(()=>{
    updateComponent();
    return ()=>{}
  },[]);
  function updateComponent(url?:string){
     const pathname=url||document.location.pathname;
     menuPrimary.map((item)=>{
       if(item.url&&item.url==pathname){
            setComponent(item.content);
            return;
       }
     });
     menuSecondary&&menuSecondary.map(item=>{
        if(item.url&&item.url==pathname){
            setComponent(item.content);
            return;
       } 
     })
  }
  function onClickUpdateUrl(url?:string){
      if(!url)
        return;
      nav(url);
      updateComponent(url);
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {headerText}
            </Typography>
            {0?<IconButton color="inherit">
              
            </IconButton>:null}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            {open?<IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>:null}
          </Toolbar>
          <Divider />
          <List component="nav">
              {menuPrimary.map((item)=>{
                  return (
                    <span onClick={()=>onClickUpdateUrl(item.url)}>
                    {item.item}
                    </span>
                  )
              })}
            <Divider sx={{ my: 1 }} />
              {menuSecondary?menuSecondary?.map(item=>{
                  return (<span onClick={()=>onClickUpdateUrl(item.url)}>
                    {item.item}
                    </span>)
              }):null}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <div style={{paddingBottom:'20px'}}></div>
            {component}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}