import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';

export function PopperComponents({anchorEl,open,component}:{anchorEl?:any,open?:boolean,component:JSX.Element}) {
  const id = anchorEl ? 'simple-popper' : undefined;

  return (
    <div>
      <Popper sx={{zIndex:5000}}  disablePortal={true} placement='top-start' id={id} open={Boolean(open)} anchorEl={anchorEl}>
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
           {component}
        </Box>
      </Popper>
    </div>
  );
}