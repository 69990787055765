import React, { useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { SelectFromItems } from "../utils/forms/select"
import { Script1State } from "../../utils/fgtschat/types";
import { updateChatFgtsChatState } from "../../utils/fgtschat/state";
const scrip1StatesArray:Array<string>=Object.values(Script1State);
export function FgtsChatControls({currentState,chatId}:{currentState:Script1State|undefined|string,chatId?:number}){
    const [updating,setUpdating]=useState(false);
    const [cState,setCurrentState]=useState(currentState);
    const [newState,setNewState]=useState<undefined|Script1State>(undefined);
    useEffect(()=>{
       setCurrentState(currentState);
    },[currentState]);
    useEffect(()=>{
        if(!updating)
           return;
         //@ts-ignore
        updateChatFgtsChatState(chatId,newState).then(e=>{
             setCurrentState(newState);
             setUpdating(false);
        });
    },[updating]);
    return (
        <div style={{margin:'auto',width:'90%',maxWidth:'300px',marginTop:'5px',marginBottom:'10px',display:'block',textAlign:'center'}}>
            <SelectFromItems disabled={!chatId||updating} defaultValue={cState} items={scrip1StatesArray} label='Atualizar Estado Chat' onChangeItem={(v:any)=>{setNewState(v)}}/>
            <div>
            <LoadingButton fullWidth loading={updating} disabled={cState==newState||!newState} variant="contained" onClick={()=>{setUpdating(true)}}>Atualizar Estado</LoadingButton>   
            </div>
           
        </div>
    )
}