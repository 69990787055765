import { URLS } from "../../env/urls";
import { sendServerRequest } from "../../server/request";

export async function waSendText(chatId:number,text:string){
   try{
       await sendServerRequest('POST',URLS.wa.sendText,{
          chatId,
          text,
       });
       return;
   }catch(e){
    throw e;
   }
}