import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ChatInfo } from "./types";
import { useEffect, useState } from "react";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { CircularLoading } from "../utils/loading";
import { LoadingButton } from "@mui/lab";
import { openGlobalSnack } from "../utils/snack";

export function PendencyButton({chat,senderPhone}:{chat:ChatInfo,senderPhone:string}){
     const [open,setOpen]=useState(false);
     return (
        <>
            <Button sx={{margin:'auto'}} onClick={()=>{setOpen(true)}}variant="contained">Pendências</Button>
            <PendencyDialog senderPhone={senderPhone} chat={chat} close={()=>setOpen(false)} open={open}/>
        </>
     )
}

export function PendencyDialog({chat,open,senderPhone,close}:{chat:ChatInfo,senderPhone:string,open:boolean,close:()=>any}){
      const [pendency,setPendency]=useState<any>(false);
      const [loadingPendency,setLoadingPendency]=useState(false);
      const [resolving,setResolving]=useState(false);
      useEffect(()=>{
           if(open&&!loadingPendency){
               setLoadingPendency(true);
           }
      },[open]);
      useEffect(()=>{
         if(loadingPendency){
            sendServerRequest('POST',`${URLS.solve.hasPendency}/${chat.clientId}`).then(e=>{
                const data=e.data.data;
                setPendency({
                    data,
                    needUp:!data.hasRG&&!data.hasCLT&&!data.hasCNH,

                });
                setLoadingPendency(false);
            }).catch(e=>{
                setLoadingPendency(false);
                
            });
         }
      },[loadingPendency]);
      useEffect(()=>{
        if(resolving){
           sendServerRequest('POST',`${URLS.solve.resolve}/${chat.clientId}`,{senderPhone:senderPhone}).then(e=>{
               openGlobalSnack('Pendência resolvida','success');
               close();
               setResolving(false);
           }).catch(e=>{
               console.log(e);
               openGlobalSnack('Erro ao resolver pendência','error');
               setResolving(false);
               
           });
        }
     },[resolving]);
      
    
      return (
          <Dialog open={open}>
              <DialogTitle>{`Pendências: ${chat.name} ${chat.info}`}</DialogTitle>
              <DialogContent>
                  {loadingPendency?<CircularLoading/>:<>
                     {pendency?<div>
                         {pendency.needUp?<p>Precisa adiconar um documento</p>:
                         <LoadingButton loading={resolving} onClick={()=>setResolving(true)} fullWidth variant="contained">Resolver Pendência</LoadingButton>}
                     </div>:<div>
                          Sem pendências.
                        </div>}
                  </>}
              </DialogContent>
              <DialogActions>
                <Button disabled={resolving} onClick={()=>{close()}}>Fechar</Button>
              </DialogActions>
          </Dialog>
      )
}

export function PendencyForm({cpf,onUpdated}:{cpf:string,onUpdated:()=>any}){
    const [pendency,setPendency]=useState<any>(false);
    const [loadingPendency,setLoadingPendency]=useState(false);
    const [resolving,setResolving]=useState(false);
    useEffect(()=>{
       setLoadingPendency(true);
    },[cpf])
    useEffect(()=>{
       if(loadingPendency){
          sendServerRequest('POST',`${URLS.solve.hasPendency}/${cpf}`,{isCPF:'1'}).then(e=>{
              const data=e.data.data;
              setPendency({
                  data,
                  needUp:!data.hasRG&&!data.hasCLT&&!data.hasCNH,

              });
              setLoadingPendency(false);
          }).catch(e=>{
              setLoadingPendency(false);
              
          });
       }
    },[loadingPendency]);
    useEffect(()=>{
      if(resolving){
         sendServerRequest('POST',`${URLS.solve.resolve}/${cpf}`,{isCPF:'1'}).then(e=>{
             openGlobalSnack('Pendência resolvida','success');
             setResolving(false);
         }).catch(e=>{
             console.log(e);
             openGlobalSnack('Erro ao resolver pendência','error');
             setResolving(false);
             
         });
      }
   },[resolving]);
    
  
    return (
        <div>
            <p>{`Pendências: ${cpf}`}</p>
            <div>
                {loadingPendency?<CircularLoading/>:<>
                   {pendency?<div>
                       {pendency.needUp?<p>Precisa adiconar um documento</p>:
                       <LoadingButton loading={resolving} onClick={()=>setResolving(true)} fullWidth variant="contained">Resolver Pendência</LoadingButton>}
                   </div>:<div>
                        Sem pendências.
                      </div>}
                </>}
            </div>
        
        </div>
    )
}