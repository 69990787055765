import { useEffect, useState } from "react";
import '../../css/panelcontrol.css';
import { CircularLoading } from "../utils/loading";
import { userGetInfo, userGetLocalInfo } from "../../user/info";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../env/router";
import ResponsiveMenu from "../utils/responsivemenu";
import { mainListItems, secondaryListItems } from "./menuitems";
export function ControlPanel(){
    const [isCheckLogin,setCheckLogin]=useState(true);
    const nav=useNavigate();
    useEffect(()=>{
       userGetInfo().then(r=>{
           if(!r){
              nav(ROUTES.login);
           }else{
              setCheckLogin(false);
           }
       })
    },[]);
    return (
        <div className="panel_control">
            {isCheckLogin?<CircularLoading text="Carregando..."/>:<>
               <UserPanelLogged/>
            </>}
        </div>
    )
}

function UserPanelLogged(){
   const [userData,setUserData]=useState<any>();
   useEffect(()=>{
        userGetLocalInfo().then(e=>{
           setUserData(e);
        });
   })
   return (
     <>
        {userData?<ResponsiveMenu menuPrimary={mainListItems} headerText={userData.name} menuSecondary={secondaryListItems}/>:<CircularLoading/>}
     </>
    
   )
}