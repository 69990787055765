import { useEffect, useState } from "react";
import GraphTypeSelect, { GraphType } from "./graphtype";
import { PropostalInfo, TableFeeId } from "./types";
import { BarChart, PieChart } from "@mui/x-charts";
import { formatLocalCurrency } from "../../utils/currency";
import { showPorcetage } from "./porcetage";
export function GraphTotalByDate({propostals,param,label,showPieLegend}:{propostals:Array<PropostalInfo>,param:string,label:string,showPieLegend?:boolean}){
   const [totalByDDD,setTotalByDDD]=useState<undefined|{[key:string]:{total:number,numberOfPropostals:number,param:string}}>();
   const [totalSum,setTotalSum]=useState(0);
   const [totalNumberPropostals,setNumberOfPropostalsTotal]=useState(0);

   useEffect(()=>{
      let data:{[key:string]:{total:number,numberOfPropostals:number,param:string}}={};
      let tsum=0;
      let tnofp=0;
      for(let p of propostals){
          let date=new Date(p.createdAt);
          let uparam:string='indefinido';
          switch(param){
             case 'utcDay':
                uparam=date.getUTCDate().toString();
                break;
             case 'hour':
                uparam=date.getHours().toString();
                break;  
             case 'utcHour':
                uparam=date.getUTCHours().toString();       
          }
          //@ts-ignore
          
          if(!data[uparam]){
            data[uparam]={
                total:0,
                numberOfPropostals:0,
                param:uparam,
            }
          }
          data[uparam].total+=p.client_withdrawn_value;
          data[uparam].numberOfPropostals++;
          tsum+=p.client_withdrawn_value;
          tnofp++;
      }
      setTotalSum(tsum);
      setNumberOfPropostalsTotal(tnofp);
      setTotalByDDD(data);
   },[propostals]) 
   return (
    <div>
         
    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
       <div style={{display:'inline-block'}}> 
        <p>{`Produção Total por ${label} (R$)`}</p>
     <BarChart
yAxis={[{label:'R$'}]}
xAxis={[{ scaleType: 'band', data: totalByDDD?Object.values(totalByDDD).map(e=>e.param):[]}]}
series={[{ data: totalByDDD?Object.values(totalByDDD).map(e=>e.total):[],valueFormatter:(p)=>showPorcetage(p,totalSum,formatLocalCurrency) }]}
width={500}
height={300}
/>
</div>
<div style={{display:'inline-block'}}>
<p>{`Número de Propostas por ${label}`}</p>
<BarChart
  xAxis={[{ scaleType: 'band', data: totalByDDD?Object.values(totalByDDD).map(e=>e.param):[]}]}
  series={[{ data: totalByDDD?Object.values(totalByDDD).map(e=>e.numberOfPropostals):[],valueFormatter:(p)=>showPorcetage(p,totalNumberPropostals) }]}
  width={500}
  height={300}
  
/>
</div>

    </div>
    </div>
)
}