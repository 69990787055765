import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export function TabDinamic({items}:{items:Array<{item:React.ReactNode|string,label:string,disabled?:boolean}>}) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {items.map((v,i)=>{
                return <Tab disabled={v.disabled} label={v.label} value={(i+1).toString()}/>
            })}
            
          </TabList>
        </Box>
        {items.map((v,i)=>{
                return (<TabPanel value={(i+1).toString()}>{v.item}</TabPanel>)
        })}
      </TabContext>
    </Box>
  );
}