import { useEffect, useState } from "react";
import { DatepickerRange } from "../../utils/daterange";
import { DisplayCard } from "../whatsapp/datadisplay";
import { formatLocalCurrency } from "../../utils/currency";
import { CircularLoading } from "../utils/loading";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { openGlobalSnack } from "../utils/snack";
import { LoadingButton } from "@mui/lab";
import { TableWithSearchAndSortEngine } from "../utils/table";
import { formatDateWithMin } from "../../utils/date";

export function CashBook(){
     const [startDate,setStartDate]=useState(new Date());
     const [endDate,setEndDate]=useState(new Date());
     const [finalCash,setFinalCash]=useState(0);
     const [loadingFinal,setLoadingFinal]=useState(true);
     const [transitions,setTransitions]=useState([]);
     const [loadingTransitions,setLoadingTransitions]=useState(true);
     useEffect(()=>{
         if(!loadingFinal)
              return;
         sendServerRequest('GET',URLS.cachBook.finalCash).then(e=>{
              setFinalCash(e.data.data.total/100);
              setLoadingFinal(false);
         }).catch(e=>{
             openGlobalSnack('Error ao carregar saldo.','error');
         })  
     },[loadingFinal]);
     useEffect(()=>{
         if(!loadingTransitions)
              return;
         sendServerRequest('POST',URLS.cachBook.transitions,{
            fromDate:startDate.setHours(12,0,0,0),
            toDate:endDate.setHours(12,0,0,0),
         }).then(e=>{
            setTransitions(e.data.data.cachBookList);
            setLoadingTransitions(false);
         }).catch(e=>{
            openGlobalSnack('Erro ao carregar transacões.','error');
            setLoadingTransitions(false);
         })
     },[loadingTransitions])
     return (
        <div style={{textAlign:'center'}}>
              <h5>Caixa (ENTRADA)/(SAÍDA)</h5>
              {loadingFinal?<CircularLoading/>:
              <div>
              <div style={{marginTop:'20px',marginBottom:'20px'}}>
                <DisplayCard primary="Saldo Final" secondary={`${formatLocalCurrency(finalCash)}`}/>
              </div>
              <DatepickerRange onUpdated={(s,e)=>{setStartDate(s);setEndDate(e)}}/>
              <LoadingButton loading={loadingTransitions} onClick={()=>setLoadingTransitions(true)} sx={{marginTop:'5px'}} variant='contained'>Atualizar Transações</LoadingButton>
              <div style={{marginTop:'20px'}}>
              {transitions.length<1?<p>Sem Transações neste periodo.</p>:
              <TableWithSearchAndSortEngine header={[
                 {label:'Valor',id:'value',showValue:(e)=>formatLocalCurrency(e.value/100),allowSort:true},
                 {label:'Operação',id:'operation'},
                 {label:'Fonte',id:'source'},
                 {label:'Data',id:'createdAt',showValue:(e)=>formatDateWithMin(e.createdAt),allowSort:true}
              ]} items={transitions}/>}
              
              </div>
              </div>}
              
              
        </div>
     )
}