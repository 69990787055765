import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
export var openGlobalSnack=(message:string,severity:string)=>{

}
export  function GlobalSnackbars() {
  const [data, setData] = React.useState<{open?:boolean,message?:string,severity?:any}>({open:false});
  React.useEffect(()=>{
      openGlobalSnack=(message:string,severity:string)=>{
           setData({
             open:true,
             message,
             severity,
           });
      }
      return ()=>{}
  },[]);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setData({open:false,message:data.message,severity:data.severity});
  };

  return (
    <div>
      <Snackbar open={data.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={data.severity||'info'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {data.message}
        </Alert>
      </Snackbar>
    </div>
  );
}