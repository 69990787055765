export function formatDateWithMin(val:string|Date){
    const d=typeof(val)=='string'?new Date(val):val;
    const year=d.getFullYear();
    const month=addZero(d.getMonth()+1);
    const day=addZero(d.getDate());
    const hour=addZero(d.getHours());
    const minutes=addZero(d.getMinutes());
    const seconds=addZero(d.getSeconds());
    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
    function addZero(number:number){
      if(number>9)
        return number.toString();
      return `0${number}`;
    }   
  }

export function calculateDateDiferenceFromNow24Hours(val:string|Date){
    const d=typeof(val)=='string'?new Date(val):val;
    const now=new Date();
    let dif=now.getTime()-d.getTime();
    dif/=1000;
    const hours=Math.trunc((dif/3600));
    dif=dif%3600;
    const minutes=Math.trunc(dif/60);
    dif=dif%60;
    const sec=Math.trunc(dif);
    if(hours>24){
         return '+1D'
    }
    if(hours>0&&minutes>0){
        return `${hours}h ${minutes}m`;
    }
    if(hours==0&&minutes>0){
         return `${minutes}m ${sec}s`;
    }
    if(hours>0&&minutes==0){
        return `${hours}h ${minutes}m ${sec}s`
    }
    return `${sec}s`;
}