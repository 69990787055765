import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { BarChart, PieChart } from '@mui/icons-material';

export enum GraphType{
    pie='pie',
    bar='bar',
}
export default function GraphTypeSelect({defaultValue,onChange,disabled}:{disabled?:boolean,defaultValue?:GraphType,onChange:(r:GraphType)=>any}) {
  const [alignment, setAlignment] = React.useState(defaultValue||GraphType.pie);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: GraphType,
  ) => {
    setAlignment(newAlignment);
    onChange(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      disabled={disabled}
    >
      <ToggleButton value={GraphType.pie}><PieChart/></ToggleButton>
      <ToggleButton value={GraphType.bar}><BarChart/></ToggleButton>
    </ToggleButtonGroup>
  );
}