import { Script1State } from "../../utils/fgtschat/types";

export function getCurrentStateAvatarSrc(state:string,stopReason:false|string){
    if(stopReason){
        return `${process.env.PUBLIC_URL}/img/stop.svg`;
    }
    switch(state){
        case Script1State.paidStop:
            return `${process.env.PUBLIC_URL}/img/paid.svg`;
        case Script1State.paidStopSilence:
            return `${process.env.PUBLIC_URL}/img/paid.svg`;    
        case Script1State.paid:
            return `${process.env.PUBLIC_URL}/img/paid.svg`;
        case Script1State.pendingPropostal:
            return `${process.env.PUBLIC_URL}/img/peding.svg`;
        case Script1State.pendingPropostalStop:
            return `${process.env.PUBLIC_URL}/img/peding.svg`;
        case Script1State.hasFounds:
            return `${process.env.PUBLIC_URL}/img/hasfounds.svg`;
        case Script1State.noFounds:
            return `${process.env.PUBLIC_URL}/img/nomoney.svg`;    
        case Script1State.needAuthBanks:
            return `${process.env.PUBLIC_URL}/img/nauth.svg`;    
        case Script1State.askForBankPermission:
            return `${process.env.PUBLIC_URL}/img/aauth.svg`;  
        case Script1State.humanSupport:
            return `${process.env.PUBLIC_URL}/img/hsupport.svg`;     
        case Script1State.stop:
            return `${process.env.PUBLIC_URL}/img/stop2.svg`;    
        case Script1State.askForPix:
            return `${process.env.PUBLIC_URL}/img/pix.svg`;       
        case Script1State.confirmPix:
            return `${process.env.PUBLIC_URL}/img/pix.svg`;    
        case Script1State.askForCPF:
            return `${process.env.PUBLIC_URL}/img/cpf.svg`;      
        case Script1State.confirmCPF:
            return `${process.env.PUBLIC_URL}/img/cpf.svg`;    
        case Script1State.wantToWithdraw:
            return `${process.env.PUBLIC_URL}/img/wantwith.svg`;      
        case Script1State.generatePropostal:
            return `${process.env.PUBLIC_URL}/img/gcontra.svg`;
        case Script1State.createdPropostal:
            return `${process.env.PUBLIC_URL}/img/ccrontrac.svg`;  
        case Script1State.processingDoc:
            return `${process.env.PUBLIC_URL}/img/processing.svg`;
        case Script1State.manual_analysis:
            return `${process.env.PUBLIC_URL}/img/manualana.svg`;
        case Script1State.manual_analysis_stop:
            return `${process.env.PUBLIC_URL}/img/manualana.svg`;       
        case Script1State.propostalExpired:
            return `${process.env.PUBLIC_URL}/img/propexpired.svg`;           
        default:
            return `${process.env.PUBLIC_URL}/img/continue.svg`;  
    }
}
