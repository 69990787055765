import { URLS } from "../env/urls";
import { sendServerRequest } from "../server/request";
export class ServerWebSocket{
     url:string;
     ws:WebSocket;
     sendPingId?:NodeJS.Timer;
     onMessage?:(data:any)=>void;
    constructor(url:string){
       this.url=url;
       this.ws=new WebSocket(url);
       this.ws.onmessage=(data:any)=>{
           const sdata=JSON.parse(data.data);
           this.onMessage&&this.onMessage(sdata);
       }
    }
    setOnopen(onopen:any){
        this.ws.onopen=onopen;
    }
    getWS(){
        return this.ws;
    }
    sendPing(){
        this.sendPingId=setInterval(()=>{
            if(this.ws.readyState==this.ws.CLOSING||this.ws.readyState==this.ws.CLOSED){
              clearInterval(this.sendPingId);
              return;
            }
            this.ws.send(JSON.stringify({action:"ping"}));
        },30000);
    }
    setOnclose(onclose:any){
        
        this.ws.onclose=onclose;
    }
    
    setOnmessage(onmessage:(data:any)=>void){
        this.onMessage=onmessage;
    }
    
    send(data:string|any){
        this.ws.send(typeof(data)=='string'?data:JSON.stringify(data));
    }
    setOnerror(onerror:any){
        this.ws.onerror=onerror;
    }
    close(reason=1000){
        this.ws.close(reason);
    }
    async auth(){
       try{
         const result=await sendServerRequest('POST',URLS.ws.getToken);
         const data=result.data;
         if(!data.hasError){
             let token=data.data.token;
             let userId=data.data.userId;
             this.ws.send(JSON.stringify({action:'auth_ws',token:token,userId:userId}));
         }
       }catch(e){
           throw e;
       }
    }
}