import { useEffect, useState } from "react";
import GraphTypeSelect, { GraphType } from "./graphtype";
import { PropostalInfo, TableFeeId } from "./types";
import { BarChart, PieChart } from "@mui/x-charts";
import { formatLocalCurrency } from "../../utils/currency";
import { calculatePropostalsSumByParam } from "./propostals";
import { showPorcetage } from "./porcetage";

export function GraphTotalByGenericParam({propostals,param,label,showPieLegend,showParamName}:{propostals:Array<PropostalInfo>,param:string,label:string,showPieLegend?:boolean,showParamName?:(p:string)=>string}){
   const [totalByDDD,setTotalByDDD]=useState<undefined|{[key:string]:{total:number,numberOfPropostals:number,param:string}}>();
   const [sumTotal,setSumTotal]=useState(0);
   const [numberOfPropostalsTotal,setTotalNumberOfPropostalsTotal]=useState(0);
   const [type,setType]=useState(GraphType.pie);
   useEffect(()=>{
      let {data,sumTotal}=calculatePropostalsSumByParam(propostals,param,showParamName)
      setTotalByDDD(data);
      setSumTotal(sumTotal.total);
      setTotalNumberOfPropostalsTotal(sumTotal.numberOfPropostals);

   },[propostals]) 
   return (
    <div>
        <GraphTypeSelect onChange={(s)=>setType(s)}/>  
    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
       <div style={{display:'inline-block'}}> 
        <p>{`Produção Total por ${label} (R$)`}</p>
     {type=='pie'?<PieChart
  series={[
    {
      data:totalByDDD?Object.values(totalByDDD).map(e=>{return {
        id:e.param,
        value:e.total,
        label:e.param,
      }
    }):[],
    valueFormatter:(v)=>showPorcetage(v.value,sumTotal,formatLocalCurrency),
    highlightScope: { faded: 'global', highlighted: 'item' },
    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    },
  ]}
  slotProps={{
    legend: { hidden: !showPieLegend },
  }}
  width={400}
  height={200}
/>:<BarChart
yAxis={[{label:'R$'}]}
xAxis={[{ scaleType: 'band', data: totalByDDD?Object.values(totalByDDD).map(e=>e.param):[]}]}
series={[{ data: totalByDDD?Object.values(totalByDDD).map(e=>e.total):[],valueFormatter:(v)=>showPorcetage(v,sumTotal,formatLocalCurrency) }]}
width={500}
height={300}
/>}
</div>
<div style={{display:'inline-block'}}>
<p>{`Número de Propostas por ${label}`}</p>
{type=='pie'?<PieChart
  series={[
    {
      data: totalByDDD?Object.values(totalByDDD).map(e=>{return {
        id:e.param,
        value:e.numberOfPropostals,
        label:e.param,
      }}):[],
      valueFormatter:(v)=>showPorcetage(v.value,numberOfPropostalsTotal),
      highlightScope: { faded: 'global', highlighted: 'item' },
      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    },
    
  ]}
  width={400}
  height={200}
  slotProps={{
    legend: { hidden: !showPieLegend },
  }}
/>:
<BarChart
  xAxis={[{ scaleType: 'band', data: totalByDDD?Object.values(totalByDDD).map(e=>e.param):[]}]}
  series={[{ data: totalByDDD?Object.values(totalByDDD).map(e=>e.numberOfPropostals):[],valueFormatter:(v)=>showPorcetage(v,numberOfPropostalsTotal) }]}
  width={500}
  height={300}
  
/>
}
</div>

    </div>
    </div>
)
}