import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, TableSortLabel, TextField } from '@mui/material';
import { removeAccentuation } from '../../utils/accentuation';
import { shortDescObjectByKey } from '../../utils/short';
import MultipleSelectChip from './forms/multipleselect';

interface  TableHeaderItem {
    id:string,label:string,allowSort?:boolean,align?:'left'|'center'|'justify',showValue?:(i:any)=>any
}

export function TableWithSearchAndSortEngine({header,items,filters}:{items:Array<any>,header:Array<TableHeaderItem>,filters?:Array<{label:string,filter:(i:any)=>boolean}>}){
    const [searchString,setSearchString]=React.useState("");
    const [sortItem,setSortItem]=React.useState<false|{id:string,direction:'asc'|'desc'}>(false);
    const [selectedItems,setSelectedItems]=React.useState(items);
    const [usedFilters,setUsedFilters]=React.useState<Array<string>>([]);
    React.useEffect(()=>{
       let sItems=filter(items);
       sItems=search(sItems);
       let sorted=getSortArray(sItems);
       if(sorted){
         sItems=sorted;
       }
       setSelectedItems(sItems);
    },[searchString,items,usedFilters]);
    React.useEffect(()=>{
      const sorted=getSortArray(selectedItems);
      if(sorted){
        setSelectedItems(sorted);
      }
    },[sortItem]);
    return (
        <Paper style={{padding:'5px',paddingTop:'12px'}}>
             <div style={{marginBottom:'20px'}}>
             <TextField label='Pesquisar' value={searchString} onChange={(e)=>setSearchString(e.target.value)} />
             {filters?<div style={{marginBottom:'15px'}}>
                 <MultipleSelectChip label="Filtros" onChange={(d)=>{setUsedFilters(d)}} items={filters.map((i)=>{return {label:i.label,value:i.label}})}/>
             </div>:null}
             </div>
             <GeralTable onChangeSort={(i)=>setSortItem(i)} header={header} items={selectedItems}/>
        </Paper>
    )
    function getSortArray(i:Array<any>){
        if(i.length<1)
            return ;
          if(!sortItem)
              return;
         let obj=shortDescObjectByKey(i,sortItem.id);
         if(sortItem.direction=='asc'){
            obj.reverse()
         }
         return obj;
    }
    function search(sItems:Array<any>){
        let ret:Array<any>=[];
        if(searchString){
             let useSearch=removeAccentuation(searchString.toLocaleLowerCase());
             for(let i of sItems){
                let finded=false;
                for(let h of header){
                    let item=h.showValue?h.showValue(i):i[h.id];
                    if(typeof(item)!=='string')
                        continue;
                    if(removeAccentuation(item).toLocaleLowerCase().indexOf(useSearch)>-1){
                        finded=true;
                        break;
                    }

                }
                if(finded){
                    ret.push(i);
                }
             }
        }else{
            ret=ret.concat(sItems);
        }
        return ret;

    }
    function filter(sItems:Array<any>){
        if(usedFilters.length==0||!filters)
              return sItems;
        let retItems:Array<any>=[];
        retItems=retItems.concat(sItems);    
        for(let fil of usedFilters){
             let func:any=undefined;
             for(let fitems of filters){
                if(fitems.label==fil){
                    func=fitems.filter;
                }
                if(func)
                     break;
             }
             if(!func)
                 continue;
            let finded:Array<any>=[];
            for(let i=0;i<retItems.length;i++){
                if(!func(retItems[i]))
                    continue;
                finded.push(retItems[i]);
            }          
            retItems=finded;      
        }
        return retItems;    
    }
}


export function GeralTable({header,items,onChangeSort}:{items:Array<any>,header:Array<TableHeaderItem>,onChangeSort?:(i:false|{id:string,direction:'asc'|'desc'})=>void}) {
    const [page,setPage]=React.useState(0); 
    const [rowPerPage,setRowPerPage]=React.useState(5);
    const [sortItem,setSortItem]=React.useState<false|{id:string,direction:'asc'|'desc'}>(false);
    React.useEffect(()=>{
       if(!onChangeSort)
        return 
      onChangeSort(sortItem)
    },[sortItem])
    return (
        <Box>
        <TableContainer sx={{}} component={Paper}>
            <Table>
            <TableHead >
                 <TableRow>
                    {header.map((v)=>{
                         return <TableCell align={v.align}>
                             {v.allowSort?<TableSortLabel direction={sortItem?sortItem.direction:undefined} active={sortItem&&sortItem.id==v.id} onClick={(e)=>{
                                if(sortItem&&sortItem.id==v.id){
                                    if(sortItem.direction=='desc'){
                                        setSortItem(false);
                                    }else{
                                        setSortItem({
                                            id:v.id,
                                            direction:'desc'
                                        })
                                    }
                                }else{
                                    setSortItem({
                                        id:v.id,
                                        direction:'asc'
                                    });
                                }
                             }}>{v.label}</TableSortLabel>:`${v.label}`}
                            </TableCell>
                    })}
                 </TableRow>
            </TableHead>
            <TableBody>
                  {items.slice((page)*rowPerPage,(page+1)*rowPerPage).map((i)=>{
                    return <TableRow>
                         {header.map(v=><TableCell>
                             {v.showValue?v.showValue(i):i[v.id]}
                         </TableCell>)}
                    </TableRow>
                  })}
            </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage='Itens por página'
                rowsPerPageOptions={[5,10,25,100]}
                page={page}
                count={items.length}
                component="div"
                rowsPerPage={rowPerPage}
                onPageChange={(e,page)=>{setPage(page)}}
                onRowsPerPageChange={(e)=>{setRowPerPage(parseInt(e.target.value))}}
             />
           </Box>
     )
}
