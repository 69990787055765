export enum  Script1State{
    blindShot='blid_shot',
    processingDoc='processing_doc',
    paidStopSilence='paid_stop_silence',
    paidStop='paid_stop',
    supportToAuthQI='support_to_auth_qi',
    pendingPropostal='pending_propostal',
    processingPropostal='processing_propostal',
    needAuthPayBankSendTrigger='need_auth_pay_bank_triger',
    needAuthPayBank='need_auth_pay_bank',
    askNeedAuthPayBank='ask_need_auth_pay_bank',
    manual_analysis='manual_analysis',
    manual_analysis_stop='manual_analysis_stop',
    propostalExpired='propostal_expired',
    askPropostalExpiredRenew='ask_propostal_expired_renew',
    pendingPropostalStop='pending_propostal_stop',
    sendSupport='send_support',
    checkingPropostal='checking_propostal',
    v8error='v8_error',
    paid='paid',
    getInTouch="get_in_touch",
    askForAppInstall='ask_for_app_install',
    needInstallApp='need_instal_app',
    askForCPF='ask_for_cpf',
    confirmCPF='confirm_cpf',
    checkClientFounds='check_client_founds',
    hasFounds='has_founds',
    wantToWithdraw='want_to_withdraw', 
    needAuthBanks='need_auth_banks',
    waitBirthDate='wait_birth_date',
    error='error',
    dontHaveApp='dont_have_app',
    askForPix='ask_for_pix',
    confirmPix='confirm_pix',
    generatePropostal='generate_propostal',
    askForBankPermission='ask_for_banks_permission',
    createdPropostal='created_propostal',
    noFounds='no_founds',
    stop='stop',
    needGoToFGTSCaixa='need_go_to_fgts_caixa',
    needBeAnnibersaryMembership='need_be_anniversary_membership',
    humanSupport='human_support',
}

