import React, {  ReactNode, useEffect, useState } from "react"
import {  userGetLocalInfo } from "../../user/info"
import { CircularLoading } from "../utils/loading";

import { ConsultInfo, WANumberResult, getTableNameByID } from "./types";

import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";


import { LoadingButton } from "@mui/lab";
import { openGlobalSnack } from "../utils/snack";
import { PropostalStatus, propostalStatusPTBR, propostalStatusPTBRToNormal } from "../types/propostals";

import { DisplayCard } from "../whatsapp/datadisplay";

import { PropostalInfo } from "./types";


import { GraphTotalByGenericParam } from "./graphgeral";
import { GraphTotalByDate } from "./propostalbyhour";
import { calculatePropostalsSumByParam, getComissionByTableName } from "./propostals";
import { formatLocalCurrency } from "../../utils/currency";
import { SelectConsultData, UpdateConsultaData } from "../utils/consultdata";
import { clientContactSourceToPTBR } from "../types/callsouce";




function calculatePropostalInfo(propostal:Array<PropostalInfo>){
   let total=0;
   let greater=0;
   let min=0;
   for(let p of propostal){
       total+=p.client_withdrawn_value;
       if(p.client_withdrawn_value>greater)
          greater=p.client_withdrawn_value;
       if(p.client_withdrawn_value<min||min==0)
          min=p.client_withdrawn_value;
   }
   return {
      minPropostal:min,
      greaterPropostal:greater,
      total,
      avereage:propostal.length?total/propostal.length:0,
   }
}
function calculateComission(propostal:Array<PropostalInfo>){
   let {data}=calculatePropostalsSumByParam(propostal,'table_fee_id',getTableNameByID);
   let ret:{[key:string]:{
       total:number,
       totalComission:number,
       comissionPorcetage:number,
       param:string,
       numberOfPropostals:number,
   }}={};
   for(let p of Object.values(data)){
        const comission=getComissionByTableName(p.param);
        ret[p.param]={
            ...p,
            comissionPorcetage:comission,
            totalComission:comission*p.total,
        }
       
   }
   return ret;
}
function PropostalsDash({propostals,consults}:{consults?:Array<ConsultInfo>,propostals?:Array<PropostalInfo>}){
    const [waNumberResult,setWANumberResult]=useState<undefined|{[key:string]:WANumberResult}>();
    const [productionTotal,setProductionTotal]=useState(0);
    const [numberOfPropostalsTotal,setTotalNumberOfPropostalsTotal]=useState(0);
    useEffect(()=>{
       if(!propostals)
          return;
       let waNumbers:{[key:string]:WANumberResult}={}
       for(let p of propostals){
            if(!waNumbers[p.sender_number]){
                waNumbers[p.sender_number]={
                    sender_number:p.sender_number,
                    numberOfPropostals:0,
                    totalValue:0,
                }
            }
            waNumbers[p.sender_number].numberOfPropostals++;
            waNumbers[p.sender_number].totalValue+=p.client_withdrawn_value;
       }
       let tprod=0;
       let tnprop=0;
       for(let d of Object.values(waNumbers)){
          tprod+=d.totalValue;
          tnprop+=d.numberOfPropostals;
       }
       setProductionTotal(tprod);
       setTotalNumberOfPropostalsTotal(tnprop);
       setWANumberResult(waNumbers);
   },[propostals]);
   if(!propostals)
      return null;
   return (
       <div style={{textAlign:'center'}}>
          {propostals.length<1?<p>Sem Propostas</p>:
             <div style={{display:'block'}}>
                 <TotalPorpostalInfo consults={consults} propostals={propostals} totalProd={productionTotal} totalNumberPropostals={numberOfPropostalsTotal}/>
                 <GraphTotalByGenericParam label='Tabela' showPieLegend={true} showParamName={getTableNameByID} propostals={propostals} param='table_fee_id'/>
                 <GraphTotalByGenericParam label='Fonte' showPieLegend={false} showParamName={clientContactSourceToPTBR}  propostals={propostals} param='call_source'/>
                 <GraphTotalByGenericParam label='WhatsApp' propostals={propostals} param='sender_number'/>
                 <GraphTotalByGenericParam label='DDD' propostals={propostals} param='clientDDD'/>
                 <GraphTotalByGenericParam label='Status' 
                     //@ts-ignore
                     showParamName={propostalStatusPTBR} 
                     propostals={propostals} 
                     param='status'/>
                <GraphTotalByDate label='Dia Do Mês UTC' propostals={propostals} param='utcDay'/>
                <GraphTotalByDate label={`Hora GMT ${(new Date().getTimezoneOffset())/-60}`} propostals={propostals} param='hour'/>
                <GraphTotalByDate label='Hora UTC' propostals={propostals} param='utcHour'/>
             </div>
          }
       </div>
   ) 
}
function TotalPorpostalInfo({consults,propostals,totalProd,totalNumberPropostals}:{consults?:Array<ConsultInfo>,propostals:Array<PropostalInfo>,totalProd:number,totalNumberPropostals:number}){
    const [comissionTotal,setComissionTotal]=useState(0);
    const [comissionInfo,setComissionInfo]=useState<null|ReactNode>(null)
    const [propostalsInfo,setPropostalInfoNode]=useState<null|ReactNode>(null);
    const [consultsProviders,setConsultsProviders]=useState<null|ReactNode>(null);
    const [conversion,setConversion]=useState<null|ReactNode>(null);
    useEffect(()=>{
         if(propostals){
              const r=calculateComission(propostals);
              const propostalInfo=calculatePropostalInfo(propostals);
              const rObj=Object.values(r);
              let total=0;

              for(let val of rObj){
                  total+=val.totalComission;
                  
              }
              let plInfoNode=<div>
                  <p>{`Maior proposta: ${formatLocalCurrency(propostalInfo.greaterPropostal)}`}</p>
                  <p>{`Menor proposta: ${formatLocalCurrency(propostalInfo.minPropostal)}`}</p>
                  <p>{`Média da proposta: ${formatLocalCurrency(propostalInfo.avereage)}`}</p>
              </div>
              let cInfo=<div>
                {total>0?rObj.map(info=>
                    <p>{`${info.param}: ${formatLocalCurrency(info.total)} * ${(info.comissionPorcetage*100).toFixed(1)}% = ${formatLocalCurrency(info.totalComission)} (${((100*(info.totalComission/total)).toFixed(1))}%)`}</p>
                ):null}
                {total>0?<p>{`Comissão média: ${(100*(total/totalProd)).toFixed(1)}%`}</p>:null}
                
              </div>
              setConversion(<div>
                <p>{`Taxa de conversão: ${!consults||consults.length==0?'Inderminado':`${((100*(propostals.length/consults.length))).toFixed(2)}%`}`}</p>
              </div>)
              setComissionTotal(total);
              setComissionInfo(cInfo);
              setPropostalInfoNode(plInfoNode);
         }
    },[propostals,totalProd]);
    useEffect(()=>{
       if(!consults||consults.length<1)
          return;
       let ret:any={};
       for(let c of consults){
         if(!ret[c.provider]){
              ret[c.provider]={
                total:0,
              }
         }
         ret[c.provider].total++;
       }
       const keys=Object.keys(ret);
       let cpro=<div>
          {keys.map(k=>{
            return <p>{`${k}: ${ret[k].total} (${((ret[k].total/consults.length)*100).toFixed(2)}%)`}</p>
          })}
       </div>
       setConsultsProviders(cpro);
    },[consults])
    return (
      <div style={{textAlign:'center',marginBottom:'50px'}}>
        <DisplayCard primary="Produção Total" toolTip={propostalsInfo} secondary={`R$ ${totalProd.toLocaleString('pt-BR')}`}/>
        <DisplayCard marginLeft='9px' toolTip={comissionTotal>0?comissionInfo:null} primary="Comissão Total" secondary={`${formatLocalCurrency(comissionTotal)}`}/>
        <DisplayCard marginLeft='9px' toolTip={conversion} primary="Número de Propostas" secondary={`${totalNumberPropostals}`}/>
        <DisplayCard marginLeft='9px' primary="Número de Consultas" toolTip={consultsProviders} secondary={`${consults?consults.length:0}`}/>
      </div>
   )
}
export function Dashboard(){
    const [loadingUser,setLoadingUser]=useState(true);
    const [canSeePropostals,setCanSeePropostals]=useState(false);
    const [selectedData,setSelectedData]=useState<undefined|UpdateConsultaData>(undefined);
    const [loadingPropostals,setLoadingPropostals]=useState(false);
    const [propostals,setPropostals]=useState<Array<PropostalInfo>|undefined>(undefined);
    const [consults,setConsultsInfo]=useState<Array<ConsultInfo>|undefined>(undefined);
    useEffect(()=>{
       userGetLocalInfo().then(e=>{
           if(e){
               setCanSeePropostals(e.permission.can_see_propostals);
               setLoadingUser(false);
           }else{
              
           }
       });
    },[])
    useEffect(()=>{
       if(!loadingPropostals||!selectedData)
           return;
       getPropostals().then(e=>{
         setLoadingPropostals(false);
       }).catch(e=>{
          openGlobalSnack('Erro ao carregar propostals','error');
          setLoadingPropostals(false);
       })
    },[loadingPropostals]);
    useEffect(()=>{
       
    },[propostals]);
    return (
        <div>
            {loadingUser?<CircularLoading/>:
            <div>
                {canSeePropostals?
                <div>
                    <SelectConsultData disabled={loadingPropostals} onUpdate={(d)=>{setSelectedData(d)}}/>
                    <div style={{textAlign:'center'}}>
                    <LoadingButton disabled={isDisabled()}  loading={loadingPropostals} onClick={()=>setLoadingPropostals(true)} variant="contained">Consultar Propostas</LoadingButton>
                    <div style={{marginTop:'40px'}}>
                    <PropostalsDash consults={consults} propostals={propostals}/>
                    </div>
                    </div>
                </div>
                :<div>
                    <p>Acesso negado.</p>
                </div>}
            </div>
            }
            
        </div>
    )   
    async function getPropostals(){
        
        if(!selectedData)
              return;
        const fromDate=selectedData.start;
        const toDate=selectedData.end;
        let propostalStatus:Array<PropostalStatus>=[];
        if(selectedData.propostalStatus&&selectedData.propostalStatus){
             propostalStatus=propostalStatusPTBRToNormal(selectedData.propostalStatus);
        }
        const sendData={
             fromDate,
             toDate,
             v8Ids:selectedData.v8,
             senderNumbers:selectedData.wa,
             status:propostalStatus,
        };
        try{
            const r=await sendServerRequest('POST',URLS.propostals.get,sendData);
            
            setPropostals(r.data.data.propostals);
            setConsultsInfo(r.data.data.consults);
        }catch(e){
            throw e;
        }
        
    }
    function isDisabled(){
         
         if(!selectedData)
            return true;
         if((!selectedData.v8||selectedData.v8.length<1)&&(!selectedData.wa||selectedData.wa.length<1))  
                return true;
         return false;
    }
}



