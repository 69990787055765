import { URLS } from "../../env/urls";
import { sendServerRequest } from "../../server/request";
import { Script1State } from "./types";

export async function updateChatFgtsChatState(chatId:number,state:Script1State){
    try{
       const r=await sendServerRequest('POST',URLS.fgtsChat.updateState,{chatId,state});
    }catch(e){
        throw e;
    }
}