import { URLS } from "../env/urls";
import { sendServerRequest } from "../server/request";

export function userLogout():Promise<any>{
    return new Promise((resolve)=>{
        sendServerRequest('POST',URLS.user.auth.logout).then(e=>{
            resolve(e.data.data);
        }).catch(e=>{
              resolve(false);
        }) 
    });
}
