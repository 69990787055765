import { ChatMessageModel, WAChat } from "./chat";
import { WAConversationList } from "./list";
import { useEffect, useRef, useState } from "react";

import { ServerWebSocket } from "../../utils/ws";
import { URLS } from "../../env/urls";
import { getWsUrl } from "../../utils/wsurl";
import { CircularLoading } from "../utils/loading";
import { SelectFromItems } from "../utils/forms/select";
import { sendServerRequest } from "../../server/request";
import { shortDescObjectByKey } from "../../utils/short";
import { ChatInfo } from "./types";
import {  formatDateWithMin } from "../../utils/date";
import { LoadingButton } from "@mui/lab";
import '../../css/emptychatcontainer.css'
import { userGetInfo } from "../../user/info";
import { openGlobalSnack } from "../utils/snack";

interface FGTSChatNormalizedInfo{
  id:number,
  currentState:string,
  senderNumber:string,
  clientPhone:string,
  client:{
      name?:string,
      phone?:string,
      id?:number,
  },
  createdAt:string,
  updatedAt:string,
  isStoped:boolean,
  stopReason:string|false,
  isPremium:boolean,

}
interface NormalizedMessages{
  sender:string,
  receiver:string,
  text?:string,
  type:string,
  createdAt:string,
  rootNumber:string,
  id:number,
  media_path?:string,
  mime_type?:string,
}



export function WAConteiner(){
     const [phones,setPhones]=useState<Array<any>>([]);
     const [selectedPhone,setSelectedPhone]=useState<false|string>(false);
     const [canSeeConversation,setCanSeeConversation]=useState<null|boolean>(null);
     useEffect(()=>{
        userGetInfo().then(e=>{
               if(!e){
                openGlobalSnack('Atualize a página','error');
                return;
               }
               setCanSeeConversation(e.permission.can_see_conversation);
        }).catch(e=>{
             openGlobalSnack('Atualize a página','error');
        })
        return ()=>{}
     },[])
     useEffect(()=>{
        if(!canSeeConversation)
           return;
        sendServerRequest('POST',URLS.chatsInfo.number).then(e=>{
             const numbers=e.data.data.numbers;
             let values=[{
                 value:'all',
                 label:'Todos',
             }];
             for(let n of numbers){
                 values.push({
                    value:n,
                    label:n
                 })
             };
             setPhones(values)
        }).catch(e=>{

        })
        
     },[canSeeConversation]);
     useEffect(()=>{
          if(selectedPhone){
              document.title=`${selectedPhone} - IATechTeam`;
          }else{
            document.title=`IATechTeam`;
          }
     },[selectedPhone])
     return (
         <>
            {!canSeeConversation?
              <div>
                {typeof(canSeeConversation)==='boolean'?<p>Acesso Negado.</p>:<CircularLoading/>}
              </div>
            :<div>
            {phones.length<1?<CircularLoading text="Procurando Números"/>:
               <>
               <div style={{margin:'auto',marginTop:'25px',width:'90%',maxWidth:'350px'}}>
               <SelectFromItems items={phones} label={'Número'} onChangeItem={(i)=>{setSelectedPhone(i)}}/>
               </div>
               <div style={{marginBottom:'25px'}}></div>
               {selectedPhone?<WAChatConteiner phone={selectedPhone}/>:null}
               </>
            }
         </div>}
         </>
         
     )
}



function WAChatConteiner({phone}:{phone:string}){
    const [selectedChat,setSelectedChat]=useState<ChatInfo|undefined>(undefined);
    const [isMobile,setIsMobile]=useState(window.innerWidth<1000);
    const [itemsHeight,setItemsHeight]=useState(window.innerHeight-150);
    const [ws,setWS]=useState<undefined|ServerWebSocket>(undefined);
    const [chats,setChats]=useState<undefined|Array<ChatInfo>>(undefined);
    const [messages,setMessages]=useState<Array<ChatMessageModel>>([]);
    const [lastPing,setLastPing]=useState(new Date());
    const [updateConection,setUpdateConection]=useState(false);
    const [mustUpdate,setMustUpdate]=useState(false);
    const [loadingChats,setLoadingChats]=useState(false);
    const ref=useRef<{senderPhone:string,getingChats?:boolean,selectedChat?:ChatInfo,messages:Array<ChatMessageModel>,chats?:Array<ChatInfo>,upJustChat?:boolean,triesReconection:number,updateChatMessages?:boolean}>({senderPhone:phone,messages:[],triesReconection:0}); 
    useEffect(()=>{
      if(!ws)
           return;
      ws.setOnmessage((data:{data:{response:string,chat?:FGTSChatNormalizedInfo,msg?:NormalizedMessages,chats?:Array<FGTSChatNormalizedInfo>,msgs?:Array<NormalizedMessages>}})=>{
         setLastPing(new Date()); 
         
         switch(data.data.response){
              case 'Authenticated':
                  ws.send({action:'addListener'});
                  break;
              case 'getChats':
                  
                 convertChatInfo(true,data.data.chats);
                 ref.current.triesReconection=0;
                 break;    
              case 'getMessages':
                 updateMessages(true,data.data.msgs);
                 break;   
              case 'listenerAdded':
                 setLoadingChats(true);
                 break;   
              case 'updatedMessage':
                if(!data.data.msg||!ref.current.selectedChat)
                   break;
               if((data.data.msg.sender===ref.current.selectedChat.clientPhone||data.data.msg.receiver==ref.current.selectedChat.clientPhone)&&
                   (ref.current.senderPhone=='all'||data.data.msg.sender===ref.current.senderPhone||data.data.msg.receiver==ref.current.senderPhone)){
                    updateMessages(false,[data.data.msg]);
                   }
                    
                break;
              case 'updatedChat':
                if(!data.data.chat)
                   break;
                if(ref.current.senderPhone!=='all'&&ref.current.senderPhone!==data.data.chat.senderNumber)
                   break;  
                if(ref.current.getingChats)
                    break;
                convertChatInfo(false,[data.data.chat]);  
                break;     
          }
      });
      ws.setOnclose((e:any)=>{setMustUpdate(true)});
      ws.setOnerror((e:any)=>{setMustUpdate(true)});
    },[ws]);
    useEffect(()=>{
      updateWS();
      return ()=>{
           if(ws){
              if(ws.ws.readyState==WebSocket.OPEN||ws.ws.readyState==WebSocket.CONNECTING){
                 ws.close();
                 setWS(undefined);
              }
           }
      }
    },[]);
    useEffect(()=>{
      ref.current.senderPhone=phone;
      if(phone){
          if(ws&&ws.ws.readyState==WebSocket.OPEN)
              setLoadingChats(true);   
      }
           
    },[phone])
    useEffect(()=>{
        ref.current.selectedChat=selectedChat;
        if(selectedChat&&ws&&!ref.current.upJustChat){
            ref.current.updateChatMessages=true;
            setMessages([]);
        }
        ref.current.upJustChat=false;
       
    },[selectedChat]);
    useEffect(()=>{
         if(messages.length==0&&ref.current.updateChatMessages){
             ref.current.updateChatMessages=false;
             if(ws&&selectedChat)
             ws.send({
              action:'getMessages',
              fgtsChatId:selectedChat.id,
            });
         }
         ref.current.messages=messages;
         
    },[messages]);
    useEffect(()=>{
        
        ref.current.chats=chats;
        if(chats&&selectedChat){
          let finded=false;
          for(let chat of chats){
              if(chat.id==selectedChat.id){
                  
                  finded=true;
                  if(chat!==selectedChat){
                    setSelectedChat(chat);
                    ref.current.upJustChat=!updateConection;
                  }
                     
              }else{
                 
              }
          }
          if(!finded){
             setSelectedChat(undefined);
          }
        }
        if(updateConection)
          setUpdateConection(false);
        if(loadingChats)
          setLoadingChats(false); 
         ref.current.getingChats=false;
    },[chats]);
    useEffect(()=>{
        if(updateConection){
           updateWS(ws);
        }
    },[updateConection])
    useEffect(()=>{
        if(ref.current.triesReconection>1){
            return;
        }
        ref.current.triesReconection++;
        setUpdateConection(true);
        
    },[mustUpdate]);
    useEffect(()=>{
        if(loadingChats){
            if(ws&&ws.ws.readyState==WebSocket.OPEN){
               ref.current.getingChats=true;
               ws.send({action:'getChats',senderNumber:phone});
            }
               
        }
    },[loadingChats]);
    return (
         <>

         <div style={{marginTop:'5px',marginBottom:'10px',display:'block',textAlign:'center'}}>
             <p>{`Conectado: Ultíma atualização ${formatDateWithMin(lastPing)}`}</p>
             <LoadingButton disabled={!mustUpdate} loading={updateConection} variant="contained" onClick={()=>setUpdateConection(true)}>{mustUpdate?"Reconectar":"Conectado"}</LoadingButton>
         </div>
         {chats?<div>
         <div style={{
          height: `${itemsHeight}px`,
          display:'flex',
          justifyContent:'center',

        }}>
          {isMobile&&selectedChat?
             null
          :<WAConversationList loading={loadingChats} selectedChat={selectedChat} height={itemsHeight} chats={chats} isMobile={isMobile} onClick={(t)=>{
                  if(!selectedChat||selectedChat.id!==t.id) {
                    setSelectedChat(t);
                    }
                  }
                  
               }/>}
          {!selectedChat?
          <>
          {!isMobile?<div  style={{height: `${itemsHeight}px`}} className="empty_chat_conteiner">

          </div>:null}</>:
          <div style={{width:'100%'}}>
          <WAChat senderPhone={selectedChat?.senderNumber} messages={messages} height={itemsHeight} header={selectedChat} closeChat={()=>setSelectedChat(undefined)}/> 
          </div>}
          </div>
          </div>:<CircularLoading text="Carregando Conversas"/>}
          </>
        
    )
    function updateWS(sws?:ServerWebSocket){
        if(sws){  
              if(sws.ws.readyState==WebSocket.OPEN||sws.ws.readyState==WebSocket.CONNECTING){
                   sws.close();
              }
        }
        let tws=getChatWs(()=>{setMustUpdate(false)});
        setWS(tws);
        return tws;
    }
    
    function convertChatInfo(setAllNew:boolean,data?:Array<FGTSChatNormalizedInfo>){
          if(!data)
             return;
          let newChats:Array<ChatInfo>=[];
          for(let c of data){
              newChats.push({
                  id:c.id,
                  name:`${c.client.name||""} ${c.client.phone||""}`.trim()||c.id.toString(),
                  currentState:c.currentState,
                  stopReason:c.stopReason,
                  isStoped:c.isStoped,
                  info:`${c.currentState} ${c.isStoped||''}`,
                  createdAt:c.createdAt,
                  senderNumber:c.senderNumber,
                  updatedAt:c.updatedAt,
                  clientPhone:c.clientPhone,
                  clientId:c.client?.id||0,
                  isPremium:c.isPremium,
              });
          }
          if(!setAllNew){
                let oldChats=ref.current.chats||[];
                for(let old of oldChats){
                     let has=false;
                     for(let n of newChats){
                        if(old.id==n.id){
                            has=true;
                            break;
                        }
                     }
                     if(has)
                        continue;
                     newChats.push(old); 
                }
          }
          let shortedChats=newChats.slice(0,3000);
          if(setAllNew){
            shortedChats=shortDescObjectByKey(newChats,'updatedAt')
          }
          setChats(shortedChats);
    }
    function updateMessages(updatedNew:boolean,msgs?:Array<NormalizedMessages>){
         
         let newMessages:Array<ChatMessageModel>=[];
         if(!msgs)
           return;
         
         for(let m of msgs){
        
             newMessages.push({
                //@ts-ignore
                type:m.type,
                message:m.text||`[${m.type}]`,
                direction:m.sender==m.rootNumber?'outgoing':'incoming',
                date:m.createdAt,
                id:m.id,
                mime_type:m.mime_type,
                media_path:m.media_path,

             });
         }
         if(!updatedNew){
            newMessages=newMessages.concat(ref.current.messages);
         }
         setMessages(shortDescObjectByKey(newMessages,'date').reverse());
    }
}



function getChatWs(onOpen:Function){
   const ws=new ServerWebSocket(getWsUrl(URLS.ws.chats));
   ws.setOnopen(()=>{
       ws.auth();
       onOpen();
       ws.sendPing();
   });
   
   return ws;
}