import { URLS } from "../env/urls";
import { sendServerRequest } from "../server/request";
export interface UserInfo{
    id:number,name:string,email:string,
    permission:{
        can_see_propostals:boolean,
        can_see_complete_propostal_info:boolean,
        can_see_conversation:boolean,
    }
}
export async function userGetLocalInfo():Promise<false|UserInfo>{
    try{
        //@ts-ignore
        if(!window.userInfo){
            //@ts-ignore
            window.userInfo=await userGetInfo();
        }
        //@ts-ignore
        return window.userInfo;
    }catch(e){
        return false;
    }
}
export function userGetInfo():Promise<false|UserInfo>{
    return new Promise(resolve=>{
        sendServerRequest('POST',URLS.user.info).then(e=>{
            resolve(e.data.data);
        }).catch(e=>{
              resolve(false);
        }) 
    });
}
