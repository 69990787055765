const goprod=false;
export function getWsUrl(path:string){
    if(goprod){
        if(process.env.NODE_ENV=="development")
           return `wss://team.iatechfinanceira.com${path}`;
    }
    const protocol=window.location.protocol=='https:'?'wss://':'ws://';
    let url=window.location.host;
    if(window.location.hostname=='localhost'){
       url='localhost:3069';
    }
    return (protocol+url+path);
}
