import { useState } from "react";
import { useEffect } from "react";
import { URLS } from "../../env/urls";
import { sendServerRequest } from "../../server/request";
import { CircularLoading } from "./loading";
import MultipleSelectChip from "./forms/multipleselect";
export function V8LoginMultipleSelect({onUpdated,disabled}:{disabled?:boolean,onUpdated:(d:Array<number>)=>any}){
    const [items,setItems]=useState<false|Array<{value:number,label:string}>>(false);
    const [loading,setLoading]=useState(true);
    useEffect(()=>{
        sendServerRequest('GET',URLS.chatsInfo.v8).then(e=>{
            const v8s=e.data.data.v8s;
            let values=[];
            for(let n of v8s){
                values.push({
                    value:n.id,
                    label:n.login
                });
            };
            setItems(values)
       }).catch(e=>{
           console.log(e);
       })
        return ()=>{};
    },[]);
    useEffect(()=>{
       if(items){
         setLoading(false);
       }
    },[items])
    return (
        <div style={{textAlign:'center'}}>
            {loading?<CircularLoading text="Carregando V8 logins."/>:
              <MultipleSelectChip disabled={disabled} onChange={d=>{
                  let ret:Array<number>=[];
                  if(!items)
                    return;
                  for(let item of d){
                      for(let save of items){
                          if(item==save.label){
                                ret.push(save.value);
                                break;
                          }
                      }
                  }
                  onUpdated(ret);

              }} label='Logins V8' items={items||[]}/>
            }
        
        </div>
    )
}
