import { Card } from "flowbite-react";

import { HtmlTooltip } from "../utils/htmltooltip";

import { ReactNode } from "react";

export function DisplayCard({primary,secondary,marginLeft,toolTip}:{primary:string,secondary:string,marginLeft?:string,toolTip?:ReactNode|string}){
    return (
        <HtmlTooltip title={toolTip} disableHoverListener={!Boolean(toolTip)}> 
        <div style={{display:'inline-block',marginLeft:marginLeft?marginLeft:undefined}}>
            <Card>
            <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">{primary}</h5>
            <div style={{textAlign:'center'}}>
                <span style={{fontSize:'18px'}} className="text-3xl font-bold text-gray-700 dark:text-white">{secondary}</span>
            </div>

            </Card>
            
        </div>
        </HtmlTooltip>
    )
}