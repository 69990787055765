import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import {Select,MenuItem,InputLabel} from "@mui/material";

export function SelectFromItems({items,defaultValue,label,onChangeItem,disabled}:{
    items:Array<{value:string,label:string}>|Array<string>,
    defaultValue?:string,
    label:string,
    onChangeItem:(v:string)=>any,
    disabled?:boolean,
}){
    useEffect(()=>{
        if(defaultValue){
            setValue(defaultValue);
        }
    },[defaultValue])
    const [value,setValue]=useState(defaultValue||"");
    const handleChange = (event:any) => {
        setValue(event.target.value);
        onChangeItem(event.target.value);
    };
    return(
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          disabled={disabled}
          onChange={handleChange}
        >
         {items.map((e)=>{
              const value=typeof(e)=='string'?e:e.value;
              const itemLabel=typeof(e)=='string'?e:e.label||e.value;
              return (<MenuItem value={value}>{itemLabel}</MenuItem>)
         })}
        </Select>
      </FormControl>
    )
}