import { useEffect } from "react";
import { CircularLoading } from "../utils/loading";
import { userLogout } from "../../user/lougout";

export function Logout(){
    useEffect(()=>{
       userLogout().then(e=>{
          window.location.reload();
       })
    })
    return (
        <CircularLoading text="Deslogando.."/>
    )
}