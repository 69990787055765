import  "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { CircularLoading } from "../utils/loading";
import { formatDateWithMin } from "../../utils/date";
import { ChatInfo } from "./types";
import { getCurrentStateAvatarSrc } from "./avatar";
import { Script1State } from "../../utils/fgtschat/types";
import { useEffect, useRef, useState } from "react";
import { waSendText } from "../../utils/wa/sendtext";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import ExpansiveButton from "../utils/viewer/expansivebutton";
import { FgtsChatControls } from "../fgtschat/controls";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { PopperComponents } from "../utils/viewer/poppter";
import { ReadyMessagesList } from "./readymessages";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { UploadClientDocs } from "./uploaddoc";
import { openGlobalSnack } from "../utils/snack";
import { PendencyButton } from "./pendency";

export interface ChatMessageModel{
    message:string,
    direction:string,
    type:'text'|'video'|'audio'|'image'|'document',
    date:string,
    id:number,
    media_path?:string,
    mime_type?:string,

}


export function WAChat({messages,header,height,senderPhone,closeChat}:{height:number,senderPhone:string,messages:Array<ChatMessageModel>,header:ChatInfo,closeChat:()=>any}){
  const [sending,setSeding]=useState(false);
  const [text,setText]=useState<undefined|string>(undefined);
  const [anchorElReadyMessage,setAnchorElReadyMessages]=useState<any|undefined>();
  const [messageValue,setMessageValue]=useState('');
  const [openPopper,setOpenPooper]=useState(false);
  const inputRef=useRef<any>(null);
  useEffect(()=>{
      if(sending&&text){
          waSendText(header.id,text).then(e=>{
              clr();
          }).catch(e=>{
             openGlobalSnack('Erro ao enviar menssagem.','error');
             clr();
          });
          
      }
      function clr(){
        setSeding(false);
        setText(undefined);
        setMessageValue('');
        setAnchorElReadyMessages(undefined)
      }
  },[sending]);
  useEffect(()=>{
      if(text)
        setSeding(true);
  },[text]);
  useEffect(()=>{
       if(messageValue.indexOf('/')==0){
          if(!openPopper)
             setOpenPooper(true);
       }else{
          if(openPopper)
             setOpenPooper(false);
       }
  },[messageValue])
  return (
    <div style={{ position: "relative", height: `${height}px` }}>
  <MainContainer style={{width:'100%'}}>
    <ChatContainer>
    
    <ConversationHeader>
    <ConversationHeader.Back onClick={closeChat}/>  
       <Avatar 
          name={header.name}
          src={getCurrentStateAvatarSrc(header.currentState,header.stopReason)}
       
       />
        <ConversationHeader.Content
            userName={header.name}
            info={`${header.info} ${header.senderNumber}`}
        />
       <ConversationHeader.Actions>
            <ExpansiveButton items={[
               {
                component:<FgtsChatControls chatId={header.id} currentState={header.currentState}/>
               },
               {
                component:<UploadClientDocs chat={header}/>
               },{
                component:<PendencyButton senderPhone={senderPhone} chat={header}/>
               }
               
            ]}/>
       </ConversationHeader.Actions>
    </ConversationHeader>
      <MessageList>
           {messages.length>0?messages.map(item=>{
               return (<MessageBody msg={item}/>)
           }):<CircularLoading text="Carregando Mensagens"/>}
      </MessageList>
      
      <MessageInput ref={inputRef} value={messageValue} onChange={(i,t,inn,nod)=>setMessageValue(t)} onInput={(e)=>{if(!anchorElReadyMessage){setAnchorElReadyMessages(e.target)}}} onSend={(h,t,i,n)=>{setText(i)}}  disabled={!canSendMessage(header.currentState,sending)} placeholder={sending?'Enviando...':header.currentState==Script1State.humanSupport?"Digite sua mensagem":`Para conversar, ative o 'Suporte Humano'.`} />
    </ChatContainer>
  </MainContainer>
  <PopperComponents component={<ReadyMessagesList onselect={(r)=>{setMessageValue(r.message);inputRef?.current?.focus()}} height={height/5} text={messageValue} ignore={!openPopper}/>} open={openPopper} anchorEl={anchorElReadyMessage}/>
  </div>
   )
   function canSendMessage(state:string,sending:boolean){
      return ((state==Script1State.humanSupport||
               state==Script1State.pendingPropostalStop||
               state==Script1State.paidStopSilence||
               state==Script1State.error||
               state==Script1State.stop||
               state==Script1State.manual_analysis_stop||
               state==Script1State.v8error)&&!sending
            )
   }
}




function MessageBody({msg}:{msg:ChatMessageModel}){
      let item=undefined;
      const [openDialog,setOpenDialog]=useState(false);
      let dcontent=null;
      let hasDialog=false;
      switch(msg.type){
          case 'image':
              let imgPath=getItemPath(msg.id,msg.media_path||'');
              item=<Message.ImageContent src={imgPath} width={100} height={100}/>
              hasDialog=true;
              dcontent=<div style={{textAlign:'center'}}><img style={{margin:'auto'}} src={imgPath} width={'80%'}/></div>
              break;
          case 'audio':
             item=(<Message.CustomContent >
                  <audio style={{height:'30px',maxWidth:'350px'}} controls>
                     <source src={getItemPath(msg.id,msg.media_path||'')} type='audio/ogg'/>
                  </audio>
                  </Message.CustomContent> )   
             break;
          case 'document':
              item=(<Message.CustomContent >
                <IconButton onClick={()=>window.open(getItemPath(msg.id,msg.media_path||''))}><AssignmentReturnedIcon/></IconButton>
                </Message.CustomContent>)
              break;
          case 'video':
            let videoPath=getItemPath(msg.id,msg.media_path||'');
            item=(<Message.CustomContent >
              <IconButton><VideoFileIcon/></IconButton>
              </Message.CustomContent>)
             hasDialog=true;
             dcontent=<div style={{textAlign:'center'}}><video controls style={{margin:'auto'}} src={videoPath} width={'80%'}/></div>
              break;    
          default:
            item=<Message.TextContent text={msg.message}/> 
            break;
      }
      return (
        <>
        <Message
           onClick={()=>{
              if(hasDialog)
                setOpenDialog(true);
            }}
            style={{cursor:`${hasDialog?'pointer':null}`}}
           //@ts-ignore
           model={{
             //@ts-ignore
             direction:msg.direction,
           }}
        >
         {item}
         
         <Message.Footer>{formatDateWithMin(msg.date)}</Message.Footer>
       </Message>  
       {hasDialog?<Dialog 
           open={openDialog}
           onClose={()=>setOpenDialog(false)}
        >
           <DialogContent>
                {dcontent}
           </DialogContent>
        </Dialog>:null}     
        </>)
      function getItemPath(id:number,path:string){
          if(path.indexOf('https://')==0)
             return path;
          return `/server/media/file/${id}/${path}`  
      }  
     
}