import React from "react";
import { useEffect,useState } from "react";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import MultipleSelectChip from "./forms/multipleselect";
import { CircularLoading } from "./loading";
export function WhatsAppNumbersMultipleSelect({onUpdated,disabled}:{disabled?:boolean,onUpdated:(wa:Array<string>)=>any}){
    const [items,setItems]=useState<false|Array<string>>(false);
    const [loading,setLoading]=useState(true);
    useEffect(()=>{
        sendServerRequest('POST',URLS.chatsInfo.number).then(e=>{
            const numbers=e.data.data.numbers;
            let values=[];
            for(let n of numbers){
                values.push(n)
            };
            setItems(values)
       }).catch(e=>{

       })
        return ()=>{};
    },[]);
    useEffect(()=>{
       if(items){
         setLoading(false);
       }
    },[items])
    return (
        <div style={{textAlign:'center'}}>
            {loading?<CircularLoading text="Carregando números."/>:
              <MultipleSelectChip disabled={disabled} onChange={d=>{onUpdated(d)}} label='Números de WhatsApp' items={items||[]}/>
            }
        
        </div>
    )
}