import { ConversationList, Search, Sidebar } from "@chatscope/chat-ui-kit-react";

import {Conversation} from "@chatscope/chat-ui-kit-react";
import {Avatar} from "@chatscope/chat-ui-kit-react";
import { stat } from "fs";
import { ChatInfo } from "./types";
import {  getCurrentStateAvatarSrc } from "./avatar";
import { calculateDateDiferenceFromNow24Hours } from "../../utils/date";
import { useEffect, useState } from "react";
import { removeAccentuation } from "../../utils/accentuation";
export function WAConversationList({chats,isMobile,height,onClick,selectedChat,loading}:{chats:Array<ChatInfo>,selectedChat?:ChatInfo,height:number,loading?:boolean,isMobile:boolean,onClick:(i:any)=>any}){
    const [showedChats,setShowedChats]=useState<Array<ChatInfo>>([]);
    const [searchValue,setSearchValue]=useState('');
    useEffect(()=>{
        
        updateShowedChats();
    },[chats]);
    useEffect(()=>{
       updateShowedChats();
    },[searchValue])
    return (
        
        <div style={{
            height: `${height}px`,
            width:isMobile?'100%':'410px',
            overflowY:'hidden',
          }}>

        <Search value={searchValue} onClearClick={()=>setSearchValue('')} style={{width:isMobile?'100%':'300px',margin:'auto',marginBottom:'10px'}} onChange={(v)=>setSearchValue(v)} placeholder="Digite para filtrar."/>
        <ConversationList
        style={{
          height: `${height}px`,
          width:isMobile?'100%':'300px',
          margin:'auto'
        }}
        loading={loading}
       >
       
     
        {showedChats.length>0?showedChats.map((item)=>{
             return (
                <>
                 <Conversation
                    info={`${item.info} ${item.senderNumber}`}
                    name={item.name}
                    
                    onClick={()=>onClick(item)}
                    active={selectedChat?selectedChat.id==item.id:undefined}
                    lastActivityTime={<ActivityTime time={item.updatedAt}/>}
                >
                    <Avatar
                       name={item.name}
                       src={getCurrentStateAvatarSrc(item.currentState,item.stopReason)}
                    />
                </Conversation>
                </>
             )
        }):<p>Sem Conversas</p>}
        </ConversationList>
        </div>
        
        
    )
    function updateShowedChats(){
         let findedChats:Array<ChatInfo>=[];
         let total=0;
         if(searchValue){
            let useSearch=treatSearchSring(searchValue);
             for(let chat of chats){
                 if(treatSearchSring(chat.name).indexOf(useSearch)>-1||
                    treatSearchSring(chat.info||'').indexOf(useSearch)>-1||
                    chat.currentState.indexOf(useSearch)>-1){
                        findedChats.push(chat);
                        total++;
                    }
                    if(total>500)
                         break;
             }
         }else{
            findedChats=findedChats.concat(chats.slice(0,500));
         }
         setShowedChats(findedChats);
    }
}

function ActivityTime({time}:{time:string}){
    return (
        <>{calculateDateDiferenceFromNow24Hours(time)}</>
    )
}

function treatSearchSring(str:string){
   return removeAccentuation(str.trim().toLowerCase());
}