import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ChatInfo } from "./types";
import { LoadingButton } from "@mui/lab";
import InputFileUpload from "../utils/fileupload";
import { SelectFromItems } from "../utils/forms/select";
import { openGlobalSnack } from "../utils/snack";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { CircularLoading } from "../utils/loading";

export function UploadClientDocs({chat}:{chat:ChatInfo}){
      const [open,setOpen]=useState(false);
      return(
         <>
            <Button fullWidth variant="contained" onClick={()=>setOpen(true)}>Adicionar Documento</Button>
            <UploadDocDialog open={open} chat={chat} close={()=>setOpen(false)} onUploaded={()=>{
                openGlobalSnack('Documentos enviados com sucesso','success');
                setOpen(false);
            }}/>
         </>
      )
}

export function UploadDocDialog({open,chat,close,onUploaded}:{open:boolean,chat:ChatInfo,close:Function,onUploaded:()=>any}){
    const [docType,setDocType]=useState<string>('RG');
    const [uploading,setUploading]=useState<false|FormData>(false);
    const [updatingChat,setUpdatingChat]=useState(false);
    const [clientInfo,setClientInfo]=useState<undefined|{hasCNH:boolean,hasCLT:boolean,hasRG:boolean,clientId:number}>(undefined);
    useEffect(()=>{
        if(open&&!updatingChat){
                setUpdatingChat(true);
        }
   },[open]);
   useEffect(()=>{
       
   },[chat])
   useEffect(()=>{
       if(updatingChat){
            sendServerRequest('POST',`${URLS.solve.hasDoc}/${chat.clientId}`).then(e=>{
                setClientInfo(e.data.data);
                setUpdatingChat(false);
            }).catch(e=>{
                setUpdatingChat(false);
                
            });
       }
   },[updatingChat])
   useEffect(()=>{
      if(uploading){
        sendServerRequest("POST",`${URLS.solve.uploadDocs}/${chat.clientId}`,uploading).then(e=>{
            setUploading(false);
            onUploaded();
       }).catch(e=>{
           setUploading(false);
            openGlobalSnack('Erro ao fazer upload dos arquivos','error');
       })
      }
   },[uploading])
    return (
        <Dialog open={open}>
        <DialogTitle>{chat.name} - {chat.info}</DialogTitle>
        <DialogContent>
               {!updatingChat?<>{clientInfo?<>
                <div style={{textAlign:'center'}}>
                   <p>{`Tem CNH: `}<span style={{color:clientInfo.hasCNH?'green':'red'}}>{`${clientInfo.hasCNH?'SIM':'NÃO'}`}</span></p>
                   <p>{`Tem RG: `}<span style={{color:clientInfo.hasRG?'green':'red'}}>{`${clientInfo.hasRG?'SIM':'NÃO'}`}</span></p>
                   <p>{`Tem CLT: `}<span style={{color:clientInfo.hasCLT?'green':'red'}}>{`${clientInfo.hasCLT?'SIM':'NÃO'}`}</span></p>
                </div>
               <div style={{marginTop:'20px',marginBottom:'20px'}}>
               <SelectFromItems disabled={Boolean(uploading)} items={['RG','CNH','CLT']} defaultValue="RG" onChangeItem={(v)=>setDocType(v)} label="Tipo de documento"/>
               </div>
            
               <form id='upload_docs_form'>
                    
                    <DocForm disabled={Boolean(uploading)} type={docType}/>
                    <div style={{marginTop:'60px'}}>
                    <LoadingButton loading={Boolean(uploading)} onClick={uploadDocs}variant="contained" fullWidth>{`Adicionar Documento (${docType})`}</LoadingButton>
                    </div>
               </form>
               </>:<p>Cliente não está apto ter seu documentos carregados</p>}</>:<CircularLoading/>}
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>{if(!uploading)close()}} disabled={Boolean(uploading)} variant="contained">Fechar</Button>
        </DialogActions>
   </Dialog>
    )
    function uploadDocs(){
        const form:any=document.getElementById('upload_docs_form');
        if(!form)
            return;
        const sendForm=new FormData(form);
        const front:any=sendForm.get('doc_front');
        const back:any=sendForm.get('doc_back');
        if(!front||!front.name){
            openGlobalSnack('Insira a foto da frente do documento.','warning');
            return;
        }
        if(docType!=='CNH'&&(!back||!back.name)){
            openGlobalSnack('Insira a foto de trás do documento.','warning');
            return;
        }
        setUploading(sendForm);
    }
}

function DocForm({type,disabled}:{type:string,disabled:boolean}){
    return (
        <div style={{textAlign:'center'}}>
            <input type='hidden' value={type} name='doc_type'/>
           <InputFileUpload disabled={disabled} label={'Frente do Documento'} name={'doc_front'} />
           {type=='CNH'?null:<InputFileUpload disabled={disabled} label={'Trás do Documento'} name={'doc_back'}/>}
        </div>
    )
}



export function UploadDocForm({cpf,onUploaded}:{cpf:string,onUploaded:()=>any}){
    const [docType,setDocType]=useState<string>('RG');
    const [uploading,setUploading]=useState<false|FormData>(false);
    const [updatingChat,setUpdatingChat]=useState(false);
    const [clientInfo,setClientInfo]=useState<undefined|{hasCNH:boolean,hasCLT:boolean,hasRG:boolean,clientId:number}>(undefined);
   
   
   useEffect(()=>{
        if(!cpf){
            if(clientInfo){
                setClientInfo(undefined);
            }
            return;
        }else{
            setUpdatingChat(true);
        }
   },[cpf]);
   useEffect(()=>{
     if(!cpf)
         return;
     if(!updatingChat)
         return;
     sendServerRequest('POST',`${URLS.solve.hasDoc}/${cpf}`,{isCPF:true}).then(e=>{
        setClientInfo(e.data.data);
        setUpdatingChat(false);
    }).catch(e=>{
        setUpdatingChat(false);
        
    });      
   },[updatingChat])
   useEffect(()=>{
      if(uploading){
        sendServerRequest("POST",`${URLS.solve.uploadDocs}/${cpf}`,uploading).then(e=>{
            setUploading(false);
            onUploaded();
       }).catch(e=>{
           setUploading(false);
            openGlobalSnack('Erro ao fazer upload dos arquivos','error');
       })
      }
   },[uploading])
    return (
        <div>
        <div>
               {!updatingChat?<>{clientInfo?<>
                <div style={{textAlign:'center'}}>
                   <p>{`Tem CNH: `}<span style={{color:clientInfo.hasCNH?'green':'red'}}>{`${clientInfo.hasCNH?'SIM':'NÃO'}`}</span></p>
                   <p>{`Tem RG: `}<span style={{color:clientInfo.hasRG?'green':'red'}}>{`${clientInfo.hasRG?'SIM':'NÃO'}`}</span></p>
                   <p>{`Tem CLT: `}<span style={{color:clientInfo.hasCLT?'green':'red'}}>{`${clientInfo.hasCLT?'SIM':'NÃO'}`}</span></p>
                </div>
               <div style={{marginTop:'20px',marginBottom:'20px'}}>
               <SelectFromItems disabled={Boolean(uploading)} items={['RG','CNH','CLT']} defaultValue="RG" onChangeItem={(v)=>setDocType(v)} label="Tipo de documento"/>
               </div>
            
               <form id='upload_docs_form'>
                    
                    <DocForm disabled={Boolean(uploading)} type={docType}/>
                    <div style={{marginTop:'60px'}}>
                    <LoadingButton loading={Boolean(uploading)} onClick={uploadDocs}variant="contained" fullWidth>{`Adicionar Documento (${docType})`}</LoadingButton>
                    </div>
               </form>
               </>:<p>Cliente não está apto ter seu documentos carregados</p>}</>:<CircularLoading/>}
        </div>
   </div>
    )
    function uploadDocs(){
        const form:any=document.getElementById('upload_docs_form');
        if(!form)
            return;
        const sendForm=new FormData(form);
        const front:any=sendForm.get('doc_front');
        const back:any=sendForm.get('doc_back');
        sendForm.append('isCPF',"true");
        if(!front||!front.name){
            openGlobalSnack('Insira a foto da frente do documento.','warning');
            return;
        }
        if(docType!=='CNH'&&(!back||!back.name)){
            openGlobalSnack('Insira a foto de trás do documento.','warning');
            return;
        }
        setUploading(sendForm);
    }
}