import { URLS } from "../env/urls";
import { sendServerRequest } from "../server/request";
import { userGetInfo } from "./info";

export function userLogin(email:string,password:string){
     return new Promise((resolve,reject)=>{
        sendServerRequest('POST',URLS.user.auth.login,{
            email:email,
            password:password,
         }).then(e=>{
             userGetInfo().then(e=>{
                resolve(e);
             }).catch(e=>{
                reject(e);
             })
         }).catch(e=>{
             reject(e);
         });
     })
     
}