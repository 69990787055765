import {
  createBrowserRouter,
} from "react-router-dom";
import { RedirectComponent } from "./components/utils/redirect";
import { ControlPanel } from "./components/control/root";
import SignIn from "./components/user/login";

const router = createBrowserRouter([
  {
     path:'/',
     element:<RedirectComponent to={'/controle'}/>
  },
  {
    path:'/login',
    element:<SignIn/>
  },
  {
     path:'/controle/*',
     element:<ControlPanel/>
  },
  
]);

export default router;
