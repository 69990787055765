import { CircularProgress } from "@mui/material";
import "../../css/loading.css";
export function CircularLoading({text}:{text?:string}){
    return(
        <div className="circular_progress">
            <div>
            <CircularProgress/>
            </div>
            <div>
               {text}
            </div>
        </div>
    )
}