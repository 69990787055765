import { Datepicker } from "flowbite-react";
import { useEffect, useState } from "react";

export function DatepickerInput({disabled,title,onSelectedDateChanged,minDate,maxDate}:{disabled?:boolean,title?:string,onSelectedDateChanged:(d:Date)=>any,minDate?:Date,maxDate?:Date}) {
  return <Datepicker disabled={disabled} minDate={minDate} defaultDate={new Date()} maxDate={maxDate} onSelectedDateChanged={(d)=>onSelectedDateChanged(d)}  title={title} language="pt-BR" labelTodayButton="Hoje" labelClearButton="Limpar" />;
}
export function DatepickerRange({title,onUpdated,disabled}:{disabled?:boolean,title?:string,onUpdated:(start:Date,end:Date)=>any}){
    const isMobile=window.innerWidth>600?false:true;
    const [startDate,setStartDate]=useState(new Date());
    const [endDate,setEndDate]=useState(new Date());
    useEffect(()=>{
       onUpdated(startDate,endDate);
    },[startDate,endDate])
    return (
        <div style={{textAlign:'center'}}>
            <p>{title}</p>
        <div style={!isMobile?{display:'flex',justifyContent:'center'}:{}}>
            <div style={{display:isMobile?'block':'inline-block',marginBottom:isMobile?'8px':undefined}}>
                <DatepickerInput disabled={disabled} maxDate={endDate} onSelectedDateChanged={(start)=>{setStartDate(start)}} title="Início"/>
            </div>
            <div style={{display:isMobile?'block':'inline-block'}}>
                <DatepickerInput disabled={disabled} minDate={startDate} onSelectedDateChanged={(end)=>{setEndDate(end)}} title="Fim"/>
            </div>
        </div>
        </div>
    )
}