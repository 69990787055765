export enum ClientContactSource{
    faceads='faceads',
    facewhatsappads='face_whatsapp_ads',
    shot='shot',
    direct='direct',
    others='others',
    unknwon='unknown',
 }
export function clientContactSourceToPTBR(s:string|ClientContactSource){
   switch(s){
      case ClientContactSource.faceads:
        return "Anuncio Face";
      case ClientContactSource.direct:
        return "Contato Direto";
      case ClientContactSource.shot:
         return "Disparo";
      case ClientContactSource.facewhatsappads:
         return "Anuncio Direto WhatsApp";
      case ClientContactSource.unknwon:
          return "Indeterminado";
      case ClientContactSource.others:
          return "Indeterminado";       
   }
   return s;
} 