export enum PropostalStatus{ 
    pending='pending',
    paid='paid',
    canceled='canceled',
    formalization='formalization',
    processing='processing',
    manualAnalysis='manual_analysis',
    analysis='analysis',
}
export interface PropostalInfoComplete{
    id:number,
    createdAt:string,
    updatedAt:string,
    status:PropostalStatus,
    table_fee_id:string,
    formalizationLink:string,
    liveStatus:PropostalStatus,
    statusReason?:string,
    client_withdrawn_value:number,
    clientPhone:string,
    paidComissiom:boolean,
    client?:{
        name?:string,
        cpf?:string,
    },
    hasSupport:boolean,
    comissioned:boolean,
    liveComissioned:boolean,
    userComission?:{
        comissionPorcetage:number,
        createdAt:Date,
    }
}
export function propostalStatusPTBRToNormal(s:Array<string>){
   const data=getPropostalsStatusArrayTranslated();
   let ret:Array<PropostalStatus>=[];
   for(let val of s){
        let finedIndex=-1;
        for(let index=0;index<data.ptBR.length;index++){
            if(data.ptBR[index]==val){
                finedIndex=index;
                break;
            }
        }
        if(finedIndex>=0){
           ret.push(data.normal[finedIndex]);
        }
   }
   return ret;
}
export function getPropostalsStatusArrayTranslated(){
    let ptBR:Array<string>=[];
    const normal=Object.values(PropostalStatus);

    for(let o of normal){
        ptBR.push(propostalStatusPTBR(o));
    }
    return {
        normal,
        ptBR
    }
}
export function propostalStatusPTBR(ps:PropostalStatus){
    switch(ps){
        case PropostalStatus.pending:
            return "Pendente";
        case PropostalStatus.paid:
            return "Pago";
        case PropostalStatus.analysis:
            return "Analise Automática";
        case PropostalStatus.canceled:
            return "Cancelado";
        case PropostalStatus.formalization:
            return "Formalização";
        case PropostalStatus.manualAnalysis:
            return "Analise Manual";
        case PropostalStatus.processing:
            return "Desembolso";
        default:
            return ps;                         
    }
}