import React, { ReactElement, useEffect, useState } from "react";
import { getPropostalsStatusArrayTranslated } from "../types/propostals";
import { DatepickerRange } from "../../utils/daterange";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { WhatsAppNumbersMultipleSelect } from "./multiplewhatsapp";
import { V8LoginMultipleSelect } from "./v8loginmultpleselext";
import MultipleSelectChip from "./forms/multipleselect";


export interface UpdateConsultaData{
    start:Date,
    end:Date,
    v8?:Array<number>,
    wa?:Array<string>,
    propostalStatus?:Array<string>,
}

export function SelectConsultData({disabled,onUpdate,disableWA}:{disableWA?:boolean,disabled?:boolean,onUpdate:(d:UpdateConsultaData)=>any}){
      const [data,setData]=useState<UpdateConsultaData>({
         start:new Date(),
         end:new Date(),
      });
      const propostals=getPropostalsStatusArrayTranslated().ptBR;

      useEffect(()=>{
          let sendData:any={};
          Object.assign(sendData,data);
          sendData.start.setHours(12,0,1,1);
          sendData.end.setHours(12,0,0);
          onUpdate(sendData);
      },[data]);
      return (
        <div>
           <DatepickerRange disabled={disabled} title="Período de consulta." onUpdated={(s,e)=>updateData({start:s,end:e})}/>
           <LabTabs disableWA={disableWA} disabled={disabled} v8={<V8LoginMultipleSelect disabled={disabled} onUpdated={d=>updateData({v8:d})}/>} wa={<WhatsAppNumbersMultipleSelect disabled={disabled} onUpdated={(d)=>updateData({wa:d})}/>}/>
           <div style={{textAlign:'center'}}>
           <MultipleSelectChip onChange={(r)=>updateData({propostalStatus:r})} label="Status Da Proposta" items={propostals}/>
           </div>
        </div>
      )
      function updateData(d:{start?:Date,end?:Date,v8?:Array<number>,wa?:Array<string>,propostalStatus?:Array<string>}){
           let newData:UpdateConsultaData={
              start:d.start||data.start,
              end:d.end||data.end,
           }
           if(d.propostalStatus){
              newData.propostalStatus=d.propostalStatus;
           }else{
              newData.propostalStatus=data.propostalStatus;
           }
           if(d.v8){
              newData.v8=d.v8;
              newData.wa=undefined;
           }
           if(d.wa){
             newData.wa=d.wa;
             newData.v8=undefined;
           }
           if(!d.v8&&!d.wa){
               newData.v8=data.v8;
               newData.wa=data.wa;
           }
           setData(newData);
      }
}




export function LabTabs({v8,wa,disabled,disableWA}:{disableWA?:boolean,disabled?:boolean,v8:ReactElement,wa:ReactElement}) {
    const [value, setValue] = React.useState('1');
  
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList  centered={true} onChange={handleChange} aria-label="lab API tabs example">
              <Tab disabled={disabled} label="V8 Login" value="1" />
              {!disableWA?<Tab disabled={disabled} label="Número de WhatsApp" value="2" />:null}
            </TabList>
          </Box>
          <TabPanel value="1">{v8}</TabPanel>
          {!disableWA?<TabPanel value="2">{wa}</TabPanel>:null}
        </TabContext>
      </Box>
    );
  }
  