import * as React from 'react';


export default function InputFileUpload({name,label,disabled}:{name:string,label:string,disabled?:boolean}) {
  
  
    return (
          <div>
             <p>{label}</p>
             <input disabled={disabled} type="file" id={`file_input_${name}`} name={name} accept="image/png, image/jpeg, application/pdf" />
          </div>
          
  )
    
}